module.exports = {
  "FORMS": "Formularios",
  "FOLDERS": "Carpetas",
  "ADMINISTRATIVE": "Administrativo",
  "INVITE": "Invitar",
  "INVITES": "Invitaciones",
  "USER_MANAGEMENT_OVERVIEW": "Vista general de los usuarios",
  "USER_MANAGEMENT_OVERVIEW_HEADER": "Información del Usuario",
  "USER_MANAGEMENT_OVERVIEW_EDIT_USER": "Editar usuario",
  "USER_MANAGEMENT_OVERVIEW_DELETE_USER": "Eliminar usuario",
  "USER_MANAGEMENT_OVERVIEW_RESEND_INVITE": "Reenviar invitación",
  "USER_MANAGEMENT_OVERVIEW_NAME": "Nombre",
  "USER_MANAGEMENT_OVERVIEW_ADMIN": "Admin",
  "USER_MANAGEMENT_OVERVIEW_NEWSLETTER": "Newsletter",
  "GROUPS": "Grupos",
  "ACCOUNT_OWNER": "Titular de la Cuenta",
  "USER_MANAGEMENT_EMAIL_ADDRESS_LABEL": "Dirección de correo electrónico",
  "USER_MANAGEMENT_ALL_USERS": "Todos los Usuarios",
  "USER_MANAGEMENT_NEW_TITLE": "Nuevos Usuario(s)",
  "USER_MANAGEMENT_EDIT_TITLE": "Administrar Usuarios",
  "USER_MANAGEMENT_PERMISSIONS_LABEL": "Permisos",
  "USER_MANAGEMENT_PERMISSIONS_ADMIN": "Admin",
  "USER_MANAGEMENT_GROUP_PERMISSIONS_NO_GROUPS": "No hay ningún grupo",
  "USER_MANAGEMENT_COLLECTION_PERMISSIONS_LABEL": "Carpetas",
  "USER_MANAGEMENT_COLLECTION_PERMISSIONS_BY_GROUPLABEL": "(por grupos)",
  "USER_MANAGEMENT_COLLECTION_PERMISSIONS_NO_COLLECTIONS": "No hay ninguna Carpeta",
  "USER_MANAGEMENT_EDIT_SECURITY": "Seguridad del Usuario",
  "USER_MANAGEMENT_SETTINGS_LABEL": "Ajustes del Usuario",
  "USER_MANAGEMENT_SETTINGS_USERNAME": "Nombre de usuario",
  "USER_MANAGEMENT_SETTINGS_FIRST_NAME": "Nombre",
  "USER_MANAGEMENT_SETTINGS_LAST_NAME": "Apellido",
  "USER_MANAGEMENT_SETTINGS_PHONE_NUMBER": "Número de teléfono",
  "USER_MANAGEMENT_SETTINGS_RECEIVE_NEWS_LETTER": "Newsletter",
  "USER_MANAGEMENT_SETTINGS_DANGER_ZONE": "Zona Peligrosa",
  "USER_MANAGEMENT_SETTINGS_REMOVE_USER": "Eliminar Usuario",
  "CUSTOMER_GROUPS_NEW_TITLE": "Nuevo Grupo",
  "CUSTOMER_GROUPS_NAME_LABEL": "Nombre",
  "CUSTOMER_GROUPS_NO_GROUPS": "No hay ningún grupo.",
  "CUSTOMER_GROUPS_NO_VISIBLE_GROUPS": "No hay grupo visible. Por favor cambia los filtros.",
  "CUSTOMER_GROUPS_USER_PERMISSIONS": "Miembros del Grupo",
  "CUSTOMER_GROUPS_ADD_BUTTON": "Añadir Grupo",
  "CUSTOMER_GROUPS_MEMBER_PREFIX": "Miembro de",
  "CUSTOMER_GROUPS_EDIT_LABEL": "Editar",
  "CUSTOMER_GROUPS_REMOVE_LABEL": "Eliminar",
  "CUSTOMER_GROUPS_EDIT_TITLE": "Editar Grupo",
  "CUSTOMER_GROUPS_CLIENT_PERMISSIONS": "Permisos del Grupo",
  "CUSTOMER_GROUPS_CLIENT_PERMISSIONS_EXPLANATION": "Este grupo puede tener acceso a los formularios de las siguientes carpetas:",
  "CUSTOMER_GROUPS_PLATFORM_PERMISSIONS": "Permisos de la Platforma",
  "CUSTOMER_GROUPS_PLATFORM_PERMISSIONS_EXPLANATION": "Este grupo puede gestionar:",
  "MANAGE_FORMS_LABEL": "Formularios & Carpetas",
  "MANAGE_REGISTRATIONS_LABEL": "Registros",
  "MANAGE_TASKS_LABEL": "Tareas",
  "MANAGE_USERS_LABEL": "Usuarios",
  "MANAGE_SSO_LABEL": "Inicio de Sesión Único",
  "MANAGE_DATA_SOURCES_LABEL": "Base de datos",
  "MANAGE_TEMPLATES_LABEL": "Plantillas",
  "MANAGE_WEBHOOKS_LABEL": "Webhooks",
  "TRANSFER_OWNERSHIP_LABEL": "Transferir la Titularidad",
  "MANAGE_ACCOUNT_LABEL": "Configuración global de la cuenta",
  "USER_MANAGEMENT_GROUP_REMOVE_GROUP": "Eliminar Grupo",
  "SELECT_GROUPS": "Seleccionar Grupos",
  "USER_COUNT": "Contiene {{value}} usuarios",
  "USER_COUNT_SINGLE": "Contiene un usuario",
  "USER_COUNT_EMPTY": "No contiene usuarios",
  "USER_MANAGEMENT_REMOVE_TITLE": "Eliminar Usuario",
  "USER_MANAGEMENT_REMOVE_MESSAGE": "¿Seguro que quieres eliminar este usuario?",
  "USER_MANAGEMENT_REMOVE_YOURSELF_TITLE": "No puedes eliminar tu propio usuario",
  "USER_MANAGEMENT_REMOVE_YOURSELF_MESSAGE": "Solicita al administrador de la cuenta que te elimine.",
  "USER_MANAGEMENT_REMOVE_FROM_GROUP_TITLE": "Eliminar usuario del grupo",
  "USER_MANAGEMENT_REMOVE_FROM_GROUP_MESSAGE": "¿Seguro que quieres eliminar al usuario de este grupo?",
  "USER_MANAGEMENT_REMOVE_INVITE_TITLE": "Eliminar Invitación",
  "USER_MANAGEMENT_INVITE_REMOVE_MESSAGE": "¿Seguro que quieres eliminar esta invitación?",
  "USER_MANAGEMENT_INVITE_REMOVE_FROM_GROUP_TITLE": "Eliminar la invitación del grupo",
  "USER_MANAGEMENT_INVITE_REMOVE_FROM_GROUP_MESSAGE": "¿Seguro que quieres eliminar la invitación de este grupo?",
  "USER_MANAGEMENT_GROUP_REMOVE_TITLE": "Eliminar Grupo",
  "USER_MANAGEMENT_GROUP_REMOVE_MESSAGE": "¿Estás seguro de que quieres eliminar este grupo? ¡No puedes deshacer esta acción! Ten en cuenta de que esto no va a eliminar los usuarios que están dentro de este grupo.",
  "USER_MANAGEMENT_IMPORTED_MODAL_TITLE": "Los usuarios han sido invitados",
  "USER_MANAGEMENT_IMPORTED_MODAL_MESSAGE": "Has invitado a <strong>{{count}} usuarios</strong> a unirse a tu cuenta.",
  "USER_MANAGEMENT_EXCEL_FILE_TOO_LARGE_MESSAGE": "El archivo seleccionado es más grande que 5MB",
  "USER_MANAGEMENT_INVITE_TYPE": "¿Cuántos usuarios quieres invitar?",
  "USER_MANAGEMENT_INVITE_SINGLE": "Un solo usuario",
  "USER_MANAGEMENT_INVITE_BULK": "Múltiples usuarios",
  "USER_MANAGEMENT_INVITE_BULK_MESSAGE": "Selecciona un archivo Excel que contenga una lista de los emails en la primera columna",
  "USER_MANAGEMENT_INVITE_LANGUAGE": "Idioma de la invitación",
  "USER_MANAGEMENT_INVITE_FAILED_TITLE": "La invitación del usuario ha fallado",
  "USER_MANAGEMENT_INVITE_FAILED_MESSAGE": "El usuario ya ha sido añadido a esta cuenta",
  "USER_MANAGEMENT_PERMISSION_CHANGE_FAILED_TITLE": "El cambio en los permisos falló",
  "USER_MANAGEMENT_GROUP_REMOVE_FAILED": "No se pudo eliminar el grupo de usuarios ya que esto dejaría al usuario actual sin el permiso para administrar los usuarios",
  "USER_MANAGEMENT_GROUP_PERMISSION_CHANGE_FAILED": "No se pudo eliminar el permiso , ya que esto dejaría al usuario actual sin el permiso para administrar los usuarios",
  "ROLES": "Roles",
  "ROLES_DEFAULT": "Roles predeterminados",
  "ROLES_CUSTOM": "Roles personalizados",
  "MANAGE_ROLES_TITLE": "Gestionar roles",
  "MANAGE_ROLES_DESCRIPTION": "Define los permisos de cada uno de los roles",
  "MANAGE_BILLING": "Gestionar facturación",
  "MANAGE_CREDITS": "Gestionar facturación",
  "MANAGE_DATA_SOURCES": "Gestionar bases de datos",
  "MANAGE_FORMS": "Gestionar formularios y carpetas",
  "MANAGE_TASKS": "Gestionar todas las tareas",
  "MANAGE_REGISTRATIONS": "Gestionar todos los registros",
  "MANAGE_USERS": "Gestionar usuarios y accesos",
  "MANAGE_SSO": "Gestionar el Inicio de Sesión Único",
  "MANAGE_SENDING_DOMAIN": "Gestionar el dominio de email",
  "MANAGE_WEBHOOKS": "Gestionar Webhooks",
  "MANAGE_TEMPLATES": "Gestionar plantillas",
  "MANAGE_ACCOUNT": "Gestionar Cuenta",
  "TRANSFER_OWNERSHIP": "Transferir titularidad",
  "CUSTOMER_USER": "Acceso a la App",
  "CREATE_FOLDER": "Crear carpetas",
  "CREATE_FORM": "Crear formularios",
  "ADD_FORM": "Añadir formulario a la carpeta",
  "REMOVE_FORM": "Eliminar formulario de la carpeta",
  "UPDATE_FOLDER": "Actualizar carpeta",
  "DELETE_FOLDER": "Eliminar carpeta",
  "CREATE_SUBMISSION": "Crear registros",
  "READ_SUBMISSION": "Ver registros",
  "DELETE_SUBMISSION": "Eliminar registros",
  "UPDATE_SUBMISSION": "Actualizar registros",
  "READ_TASK": "Ver tareas",
  "UPDATE_TASK": "Completar tareas",
  "CREATE_TASK": "Crear tareas",
  "DELETE_TASK": "Anular tareas",
  "UPDATE_FORM": "Actualizar formulario",
  "ARCHIVE_FORM": "Eliminar formulario",
  "RESTORE_FORM": "Recuperar formulario",
  "MANAGE_BILLING_TOOLTIP": "Actualizar tu suscripción, datos de facturación y forma de pago. Ver tus facturas y tu historial de uso.",
  "MANAGE_CREDITS_TOOLTIP": "Actualizar tu suscripción, datos de facturación y forma de pago. Ver tus facturas y tu historial de uso.",
  "MANAGE_DATA_SOURCES_TOOLTIP": "Crear, ver, actualizar y eliminar todas las bases de datos",
  "MANAGE_FORMS_TOOLTIP": "Crear, ver y actualizar <b>TODOS</b> los formularios y carpetas",
  "MANAGE_TASKS_TOOLTIP": "Crear, ver y actualizar tareas para <b>TODOS</b> los formularios",
  "MANAGE_REGISTRATIONS_TOOLTIP": "Ver, eliminar y reenviar registros de <b>TODOS</b> los formularios",
  "MANAGE_USERS_TOOLTIP": "Gestionar el acceso de todos los usuarios, invitaciones y grupos",
  "MANAGE_SSO_TOOLTIP": "Gestionar la configuración del Inicio de Sesión Único",
  "MANAGE_SENDING_DOMAIN_TOOLTIP": "Gestionar la configuración del dominio de email personalizado",
  "MANAGE_WEBHOOKS_TOOLTIP": "Crear, ver, actualizar y eliminar todos los Webhooks",
  "MANAGE_TEMPLATES_TOOLTIP": "Crear, ver, actualizar y eliminar todas las plantillas ",
  "MANAGE_ACCOUNT_TOOLTIP": "Gestionar la configuración global de la cuenta",
  "TRANSFER_OWNERSHIP_TOOLTIP": "Gestionar la configuración global de la cuenta",
  "CREATE_FOLDER_TOOLTIP": "Crear nuevas carpetas",
  "CREATE_FORM_TOOLTIP": "Crear dentro de una carpeta a la que tienes acceso",
  "ADD_FORM_TOOLTIP": "Añadir/mover a una carpeta a la que tienes acceso",
  "REMOVE_FORM_TOOLTIP": "Eliminar de una carpeta a la que tienes acceso",
  "UPDATE_FOLDER_TOOLTIP": "Actualiza nombre, descripción e icono",
  "DELETE_FOLDER_TOOLTIP": "Eliminar carpeta",
  "CREATE_SUBMISSION_TOOLTIP": "Envía un formulario en la app para crear un nuevo registro",
  "READ_SUBMISSION_TOOLTIP": "Ver los registros de un formulario específico",
  "DELETE_SUBMISSION_TOOLTIP": "Eliminar los registros de un formulario específico",
  "UPDATE_SUBMISSION_TOOLTIP": "Actualizar los registros de un formulario específico",
  "CREATE_TASK_TOOLTIP": "Crear tareas para un formulario específico",
  "READ_TASK_TOOLTIP": "Ver tareas para un formulario específico",
  "UPDATE_TASK_TOOLTIP": "Completar tareas para un formulario específico",
  "DELETE_TASK_TOOLTIP": "Anular tareas para un formulario específico",
  "UPDATE_FORM_TOOLTIP": "Editar y publicar un formulario",
  "ARCHIVE_FORM_TOOLTIP": "Eliminar formulario",
  "ALLOWED": "Sí",
  "ALLOW": "Permitir",
  "DONT_ALLOW": "No permitir",
  "NOT_ALLOWED": "No",
  "ACCESS_ON_FOLDERS_AND_FORMS": "Carpetas y Formularios",
  "ACCESS_ON_FOLDERS_AND_FORMS_DESCRIPTION": "Gestiona el acceso de todos los usuarios de este grupo",
  "GROUP_ALL_FORMS_ACCESS_VIA_ROLE": "Este grupo puede <b>gestionar todos los formularios y carpetas</b> a través de su rol mencionado anteriormente",
  "USER_ALL_FORMS_ACCESS_VIA_ROLE": "Este usuario puede <b>gestionar todos los formularios y carpetas</b> a través de su rol",
  "ACCESS_ON_FOLDERS_AND_FORMS_USER_DESCRIPTION": "Gestiona el acceso de este usuario",
  "ACCESS_ON_ACCOUNT": "Cuenta",
  "ACCESS_ON_ACCOUNT_GROUP_DESCRIPTION": "Selecciona un rol para todos los usuarios de este grupo",
  "ACCESS_ON_ACCOUNT_USER_DESCRIPTION": "Selecciona un rol para este usuario",
  "NEW_ROLE": "Crear rol",
  "USER_IN_GROUPS_DESCRIPTION": "Este usuario pertenece a los siguientes grupos",
  "USER_NOT_IN_GROUP_DESCRIPTION": "Este usuario no pertenece a ningún grupo",
  "ACCESS_VIA_GROUPS": "Acceso mediante grupos",
  "ROLE_ACCOUNT_OWNER": "Titular de la Cuenta",
  "ROLE_ACCOUNT_ADMINISTRATOR": "Administrador de la Cuenta",
  "ROLE_OWNER": "Responsable de la Cuenta",
  "ROLE_USER": "Usuario de la Cuenta",
  "ROLE_FORM_MANAGER": "Responsable de Formularios",
  "ROLE_FORM_USER": "Usuario de Formularios",
  "ROLE_FOLDER_MANAGER": "Responsable de Carpetas",
  "USER_DESCRIPTION": "Información del Usuario",
  "ROLE_DELETE_TITLE": "Eliminar rol",
  "ROLE_DELETE_MESSAGE": "Solo puedes eliminar roles que no se utilizan. Asegúrate de actualizar primero todos los usuarios/grupos que utilizan este rol.",
  "EXTERNAL_DIRECTORY_GROUP_LABEL": "External",
  "EXTERNAL_DIRECTORY_GROUP_DESCRIPTION": "Este grupo se sincroniza desde tu directorio externo y no puede ser modificado",
  "EXTERNAL_DIRECTORY_USER_LABEL": "External",
  "EXTERNAL_DIRECTORY_USER_DESCRIPTION": "Este usuario se sincroniza desde tu directorio externo y no puede ser modificado",
  "USER_SUSPENDED": "Suspended",
  "USER_SUSPENDED_TOOLTIP": "Este usuario ha sido suspendido de tu directorio externo",
  "INVITE_OWNER_TRANSFER_NOT_ALLOWED_TITLE": "No se permite transferir la titularidad",
  "INVITE_OWNER_TRANSFER_NOT_ALLOWED_MESSAGE": "No está permitido transferir la titularidad a un usuario invitado. El usuario tiene que aceptar la invitación primero.",
  "MANAGE_USER_OWNER_TRANSFER_NOT_ALLOWED_TITLE": "No se permite transferir la titularidad",
  "MANAGE_USER_OWNER_TRANSFER_NOT_ALLOWED_MESSAGE": "Es necesario ser titular de la cuenta para poder transferir la titularidad a otro usuario",
  "TRANSFER_OWNERSHIP_CONFIRM_TITLE": "Transferir titularidad",
  "TRANSFER_OWNERSHIP_CONFIRM_MESSAGE": "¿Seguro que quieres transferir la titularidad a <em>{{firstName}} {{lastName}} ({{username}})</em>?<br /> <br /> <b>Nota:</b> Perderás la titularidad de la cuenta.",
  "TRANSFER_OWNERSHIP_CONFIRMED_TITLE": "Titularidad transferida",
  "TRANSFER_OWNERSHIP_CONFIRMED_MESSAGE": "<em>{{firstName}} {{lastName}} ({{username}})</em> es ahora titular de esta cuenta.",
  "MENU_WIDGETS": "Widgets",
  "MENU_EMAIL": "Email",
  "MENU_RULES": "Reglas",
  "MENU_HOOKS": "Integraciones",
  "SUB_FORM_NO_LONGER_USED_TITLE": "El subformulario ya no se utiliza",
  "SUB_FORM_NO_LONGER_USED_BODY": "El subformulario no se utiliza en ningún otro lugar de la carpeta. ¿Quieres eliminar el subformulario?",
  "MULTIPLE_SUB_FORM_NO_LONGER_USED_BODY": "Estos subformularios no se utilizan en ningún otro lugar de la carpeta. ¿Quieres eliminar los subformularios?",
  "UNSUPPORTED_FILE_TYPE": "Sólo se admiten los siguientes tipos de archivo: {{fileTypes}}",
  "FORM_FINALIZED_TITLE": "Esta versión del formulario no se puede editar",
  "FORM_FINALIZED_MESSAGE": "Si quieres editar tu formulario, vuelve a la Vista General y haz click en 'Editar'.",
  "PUBLISH_FAILED_TITLE": "No se ha podido publicar tu formulario",
  "PUBLISH_FAILED_MESSAGE": "No te preocupes, el formulario se ha guardado correctamente, pero no se ha podido publicar. Por favor, vuelve a intentarlo más tarde. Si el problema persiste, contáctanos.",
  "INTEGRATION_EOL_MESSAGE": "Este formulario utiliza una integración que dejó de funcionar. Por favor, elimínala o sustitúyela por otra integración o webhook. <a href=\"https://help.moreapp.com/es/support/tickets/new\">Contacta con soporte</a> si necesitas ayuda.",
  "USER_PICKER_ADD_USER": "Invitar usuario(s)",
  "USER_PICKER_ADD_GROUP": "Crear un grupo nuevo",
  "USER_PICKER_ALL_USERS": "Todos los usuarios",
  "USER_PICKER_INVITES": "Invitar",
  "USER_PICKER_INVITED": "(Invitado)",
  "COLLECTION_PICKER_ADD_FORM": "Nuevo Formulario",
  "COLLECTION_PICKER_ADD_COLLECTION": "Nueva Carpeta",
  "SEARCH_FORMS_PLACEHOLDER": "Busca carpetas y formularios",
  "VIEW_MANAGEMENT_REGISTRATIONS_SELECT_COLUMNS": "Seleccionar Columna",
  "VIEW_MANAGEMENT_REGISTRATIONS_SEARCH": "Buscar",
  "VIEW_MANAGEMENT_REGISTRATIONS_VIEW_REGISTRATION": "Ver",
  "VIEW_MANAGEMENT_REGISTRATIONS_DELETE_ALL": "Eliminar Todo",
  "VIEW_MANAGEMENT_REGISTRATIONS_DELETE_ALL_TITLE": "¿Estás absolutamente seguro?",
  "VIEW_MANAGEMENT_REGISTRATIONS_DELETE_ALL_MESSAGE": "¿Está seguro de que desea borrar todos los registros de esta forma? No se puede deshacer esta acción!",
  "VIEW_MANAGEMENT_REGISTRATIONS_DELETE_ALL_REGISTRATIONS": "Esto elimina TODOS los registros de este formulario, no los de tu selección",
  "VIEW_MANAGEMENT_REGISTRATIONS_DELETE_ALL_VERIFICATION_MESSAGE": "Por favor, introduce tu número de cliente (ID) para confirmar",
  "VIEW_MANAGEMENT_REGISTRATIONS_EXPORT": "Exportar",
  "VIEW_MANAGEMENT_REGISTRATIONS_EXPORT_ALL": "Exportar Todo",
  "VIEW_MANAGEMENT_REGISTRATIONS_EXPORT_FILTERED": "Exportar Filtrado",
  "VIEW_MANAGEMENT_REGISTRATIONS_EXPORT_SELECTED": "Exportar Seleccionados",
  "VIEW_MANAGEMENT_REGISTRATIONS_EXPORT_IMAGES": "Incluir imágenes",
  "VIEW_MANAGEMENT_REGISTRATIONS_EXPORT_INCLUDE_FILES": "Incluir archivos",
  "VIEW_MANAGEMENT_REGISTRATIONS_EXPORT_SINGLE_SHEET": "Exportar a una sola hoja de Excel",
  "VIEW_MANAGEMENT_REGISTRATIONS_EXPORT_MIGHT_TAKE_LONG_TITLE": "Exportarlo tardará un poco",
  "VIEW_MANAGEMENT_REGISTRATIONS_EXPORT_MIGHT_TAKE_LONG_MESSAGE": "Estás a punto de exportar una gran cantidad de registros. La descarga puede tardar un poco.",
  "VIEW_MANAGEMENT_REGISTRATIONS_NO_USERS": "Ningún usuario para este formulario.",
  "VIEW_MANAGEMENT_REGISTRATIONS_SELECTED": "Seleccionado",
  "VIEW_MANAGEMENT_REGISTRATIONS_CLEAR_SELECTED": "Deseleccionar",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_TITLE": "Detalles de Registro",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_DELETE_BUTTON": "Eliminar Registro",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_DELETE_MODAL_TITLE": "Eliminar Registro",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_DELETE_MODAL_MESSAGE": "¿Estás seguro de que quieres eliminar este registro? Esta acción no se puede deshacer",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_RESEND_BUTTON": "Reenviar Registro",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_RESEND_MODAL_TITLE": "Reenviar Registro",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_RESEND_MODAL_MESSAGE": "¿Quieres volver a enviar este registro?",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_RESEND_SUCCESS_MESSAGE": "El registro se ha enviado",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_RESEND_ERROR_MESSAGE": "Fallo al reenviar el registro. Contacta support, si esto permanece.",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_MAIL_SECTION": "Email con PDF",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_MAIL_SECTION_RECIPIENTS": "Destinatario",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_MAIL_SECTION_PDF": "PDF",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_INFO_SECTION": "Info",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_INFO_SECTION_USER": "Usuario",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_DATA_SECTION": "Datos",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_FILE_DOWNLOAD": "Todos los archivos",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_META_SECTION": "Meta",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_META_SECTION_LOCATION": "Localización",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_META_SECTION_SHOW_MAP": "Mostrar en Google Maps",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_META_SECTION_NO_LOCATION": "No se ha registrado",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_META_SECTION_DATE": "Fecha del envío",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_META_SECTION_DEVICE": "Dispositivo",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_META_SECTION_NETWORK": "Red",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_META_SECTION_APP_VERSION": "Versión de la aplicación",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_META_SECTION_ID": "ID",
  "VIEW_MANAGEMENT_REGISTRATIONS_DETAIL_META_SECTION_TASK": "Tarea",
  "SERIAL_NUMBER": "número de serie",
  "VIEW_MANAGEMENT_PIPELINE_DETAIL_TITLE": "Integraciones",
  "VIEW_MANAGEMENT_PIPELINE_DETAIL_RETRY": "Reintenta paso {{index}}",
  "VIEW_MANAGEMENT_PIPELINE_DETAIL_DOWNLOAD_REPORT": "Descargar informe",
  "VIEW_MANAGEMENT_PIPELINE_DETAIL_NEXT_EXECUTION": "Siguiente intento en",
  "VIEW_MANAGEMENT_MAIL_STATUS_DETAIL_TITLE": "Mail",
  "VIEW_MANAGEMENT_MAIL_STATUS_DETAIL_DOWNLOAD_PDF": "Descargar PDF",
  "VIEW_MANAGEMENT_MAIL_STATUS_RESEND_ATTEMPT": "Intento de reenvío",
  "DOWNLOAD": "Descargar",
  "MAIL_STATUS_SEND": "{{number}} enviados",
  "MAIL_STATUS_QUEUED": "{{number}} en cola",
  "MAIL_STATUS_SOFT_BOUNCE": "{{number}} soft bounced",
  "MAIL_STATUS_HARD_BOUNCE": "{{number}} hard bounced",
  "MAIL_STATUS_REJECT": "{{number}} rechazados",
  "BAD_MAILBOX": "Bad mailbox",
  "INVALID_DOMAIN": "Invalid domain",
  "IMAGES": "Imágenes",
  "UNPAID_SUBMISSION": "Este envío aún no ha sido procesado.",
  "TRY_BETA_EXPORT": "Prueba la nueva versión beta",
  "EXPORT_ALL_COLUMNS": "Exportar todas las columnas",
  "SELECTED_COLUMNS": "Seleccionar columnas",
  "EXPORT_OPTIONS_TIMEZONE": "Zona horaria",
  "EXPORT_SUCCESS_TITLE": "Exportación iniciada con éxito",
  "EXPORT_SUCCESS_MESSAGE": "Tu exportación se ha iniciado. Una vez finalice, se enviará a <b>{{email}}</b>. Dependiendo del volumen de la exportación, puede tardar hasta varias horas.",
  "EXPORT_SERVICE_UNAVAILABLE": "La exportación no está disponible en este momento. Por favor, inténtalo más tarde.",
  "EXPORT_FAILED_FILES_SUBMISSION_LIMIT": "Las exportaciones que incluyen archivos están limitadas a {{limit}} registros",
  "MORE_INFORMATION": "Más información",
  "SELECTABLE_REORDARABLE_DONE": "Hecho",
  "ACCEPT_CUSTOMER_INVITE": "¿Quieres aceptar la invitación de '{{customer}}'?",
  "ACCEPT_BUTTON": "Aceptar",
  "ACTIVATION_FORM_TITLE": "Activación de la Cuenta",
  "ACTIVATION_CUSTOMER_INFORMATION_TITLE": "Información del Cliente",
  "ACTIVATION_CUSTOMER_INFORMATION_NAME": "Nombre",
  "ACTIVATION_USER_INFORMATION_TITLE": "Información del Usuario",
  "ACTIVATION_USER_INFORMATION_FIRST_NAME": "Nombre",
  "ACTIVATION_USER_INFORMATION_LAST_NAME": "Apellido",
  "ACTIVATION_PASSWORD": "Contraseña",
  "ACTIVATION_PASSWORD_CONFIRM": "Confirmación de la Contraseña",
  "ACTIVATION_PASSWORD_MISMATCH": "Las Contraseñas no coinciden. Por favor, asegúrate de que la contraseña coincida con la de confirmación.",
  "ACTIVATION_FORM_TOC": "Términos y Condiciones",
  "ACTIVATION_FORM_TOC_MESSAGE": "Haciendo click aseguras de que estás de acuerdo con: ",
  "ACTIVATION_SUBMIT_BUTTON": "Activar",
  "ACTIVATION_ENTER_TOKEN_TITLE": "Entrar Token",
  "ACTIVATION_TOKEN_ENTRY_TOKEN": "Token",
  "ACTIVATION_INVALID_TOKEN_TITLE": "Tu invitación ha caducado o no es válida",
  "ACTIVATION_INVALID_TOKEN_MESSAGE": "Por favor, pídele a tu administrador que te envíe una nueva invitación",
  "ACTIVATION_CHECK_TOKEN": "Comprobar Token",
  "ACTIVATION_SUCCESS": "¡Sí! ¡Has activado tu cuenta con éxito!",
  "LICENSE_AGREEMENT": "Acuerdo de Licencia",
  "PRIVACY_POLICY": "Política de Privacidad",
  "SECURITY_STATEMENT": "Declaración de Seguridad",
  "LOGIN_ON_MOBILE_APP": "Inicia sesión en la aplicación móvil:",
  "APP_DOWNLOAD_INSTRUCTIONS": "Descarga la app en tu dispositivo Android o iOS para empezar.",
  "OR": "o",
  "SCAN_QR_CODE": "Escanea el código QR",
  "NO_SMARTPHONE": "¿No dispones de un smartphone ahora mismo?",
  "OPEN_IN_WEBCLIENT": "Abrir en el navegador",
  "ICON_PICKER_SELECT": "Seleccionar Icono",
  "ICON_PICKER_MODAL_TITLE": "Seleccionador de Iconos",
  "MORE_GRID_VIEW": "Vista",
  "MORE_GRID_LOAD_MORE": "Cargar más",
  "MORE_GRID_OBJECT_SHOW_DETAILS": "Detalles",
  "MORE_GRID_OBJECT_DETAILS_HEADER": "Detalles",
  "DATE_FILTER_FROM_LABEL": "Del",
  "DATE_FILTER_TO_LABEL": "Al",
  "DATE_FILTER_PLACEHOLDER": "Seleccionar fecha",
  "NO_RESULTS_FOUND": "No se han encontrado resultados",
  "CANVAS_NOT_SUPPORTED_TITLE": "La firma no es compatible",
  "CANVAS_NOT_SUPPORTED_MESSAGE": "No puedes utilizar esta funcionalidad en la Plataforma. Solo es compatible con la aplicación móvil y el Webclient.",
  "PHOTO_CAPTURE_NOT_SUPPORTED_TITLE": "Foto no es compatible",
  "PHOTO_CAPTURE_NOT_SUPPORTED_MESSAGE": "No puedes utilizar esta funcionalidad en la Plataforma. Solo es compatible con la aplicación móvil y el Webclient.",
  "PHOTO_LIBRARY_NOT_SUPPORTED_TITLE": "Biblioteca de fotos no es compatible",
  "PHOTO_LIBRARY_NOT_SUPPORTED_MESSAGE": "No puedes utilizar esta funcionalidad en la Plataforma. Solo es compatible con la aplicación móvil y el Webclient.",
  "BARCODE_SCAN_FAILURE": "Escaneo de código de barras no es compatible",
  "BARCODE_NOT_SUPPORTED_MESSAGE": "No puedes utilizar esta funcionalidad en la Plataforma. Solo es compatible con la aplicación móvil.",
  "DATE_MODAL_TITLE": "Seleccionador",
  "WIDGET_DETAIL_LINK": "Permitir que el subformulario se reutilice en otros widgets-Subformulario",
  "WIDGET_DETAIL_UNLINK": "Vincular este widget a un subformulario reutilizable",
  "WIDGET_DETAIL_NEW_TITLE": "Nuevo Subformulario",
  "WIDGET_DETAIL_REMOVE_TITLE": "Eliminar Subformulario",
  "WIDGET_DETAIL_LINK_DESCRIPTION": "Vincular un subformulario existente",
  "WIDGET_DETAIL_LINKED_DESCRIPTION": "Los cambios se aplicarán a otros widgets que utilicen este subformulario",
  "WIDGET_DETAIL_USAGE_DESCRIPTION": "Utilizado en {{value}} widgets",
  "WIDGET_DETAIL_USAGE_DESCRIPTION_UNUSED": "No utilizado",
  "WIDGET_DETAIL_REMOVE_MESSAGE": "¿Estás seguro de que quieres eliminar este subformulario?",
  "COLLECTION": "Carpeta",
  "DESCRIPTION": "Descripción",
  "LOGIN": "Iniciar sesión",
  "LOGIN_USERNAME": "Nombre de usuario",
  "LOGIN_PASSWORD": "Contraseña",
  "LOGIN_SIGN_IN_BUTTON": "Iniciar Sesión",
  "LOGIN_INVALID_USERNAME_PASSWORD_COMBINATION": "Combinación nombre de usuario/contraseña incorrecto",
  "LOGIN_FORGOT_PASSWORD_LINK": "¿Has olvidado tu contraseña?",
  "LOGIN_NO_ACCOUNT_MESSAGE": "¿No tienes cuenta? ¡Selecciona tu plan aquí!",
  "LOGOUT_SUCCESSFUL_TITLE": "Sesión cerrada",
  "LOGOUT_SUCCESSFUL_MESSAGE": "Tu sesión ha sido cerrada",
  "LOGOUT_SUCCESSFUL_ACTION": "Iniciar sesión",
  "FORGOT_PASSWORD_TITLE": "¿Has olvidado tu contraseña?",
  "FORGOT_PASSWORD_EMAIL": "Email",
  "FORGOT_PASSWORD_USERNAME": "Nombre de usuario",
  "FORGOT_PASSWORD_SUBMIT": "Enviar",
  "FORGOT_PASSWORD_INVALID_EMAIL": "Email incorrecto",
  "FORGOT_PASSWORD_EMAIL_SENT": "Tu nueva contraseña ha sido reenviada. Sigue el link que te hemos enviado a tu dirección de correo electrónico para restablecer tu nueva contraseña.",
  "FORGOT_PASSWORD_BACK_TO_LOGIN": "Volver",
  "ACTIVATE_USER_TITLE": "Activar usuario",
  "ACTIVATE_USER_TICKET": "Ticket",
  "ACTIVATE_USER_NEW_PASSWORD_INPUT": "Nueva contraseña",
  "ACTIVATE_USER_NEW_PASSWORD_VERIFY_INPUT": "Nueva contraseña (verificación)",
  "MENU_HELP": "Ayuda",
  "MENU_REGIONS": "Regiones",
  "MENU_CUSTOMERS": "Clientes",
  "MENU_LOGOUT": "Cerrar sesión",
  "MENU_USERS": "Usuarios",
  "MENU_REGISTRATIONS": "Registros",
  "MENU_FORMS": "Formularios",
  "MENU_TEMPLATES": "Plantillas",
  "MENU_SETTINGS": "Ajustes",
  "MENU_API_DOC": "Docs",
  "MENU_DOCUMENTATION": "Emails",
  "MENU_SUPPORT": "Soporte",
  "MENU_PLUGINS": "Plugins",
  "MENU_TRANSACTIONS": "Transacciones",
  "MENU_BILLING": "Facturación",
  "MENU_FINANCIAL": "Finanzas",
  "MENU_CONTACT": "Contactar",
  "MENU_FAQ": "FAQ",
  "MENU_CHOOSE_CUSTOMER_LABEL": "Elegir cliente",
  "MENU_CLIENTS": "Apps",
  "MENU_DATA_SOURCES": "Datos",
  "MENU_PROFILE": "Perfil",
  "MENU_ONBOARDING": "Registro",
  "MENU_QUEUE": "Queue",
  "PROFILE_INFO": "Info",
  "PROFILE_EMAIL_VALID": "Tu email ha sido validado con éxito",
  "PROFILE_EMAIL_NOT_VALIDATED": "Tu email aún no ha sido validado.",
  "SUBMITTED": "Enviado",
  "SENT": "Enviada",
  "ACCEPTED": "Aceptado",
  "REVOKED": "Revocada",
  "REJECTED": "Rechazada",
  "CONFIRM": "Confirmar",
  "AWAITING_PACKAGE_UPLOAD": "En espera de cargar el paquete",
  "RESELLER": "Distribuidor",
  "KICKBACK": "Kickback",
  "CUSTOMER_APPLICATION_SETTINGS": "Ajustes",
  "CUSTOMER_APPLICATION_OPERATIONS": "Operaciones de la Carpeta",
  "CUSTOMER_FOLDER_FORMS_ACTIVE": "La carpeta solo se puede eliminar si está vacía",
  "CUSTOMER_USAGE_TOTAL_FORMS_SENT": "Total de formularios enviados",
  "CUSTOMER_USAGE_STATISTICS_FORMS_SENT": "Formularios enviados",
  "SIMULATOR_SEARCH_WIDGET_PLACEHOLDER": "Buscar...",
  "ADD_FORM_PANEL_TITLE": "Añadir un formulario",
  "ADD_FORM_PANEL_TITLE_LABEL": "Título del formulario",
  "ADD_FORM_PANEL_TITLE_PLACEHOLDER": "Título del formulario",
  "ADD_FORM_PANEL_SAVE": "Guardar",
  "ADD_TEMPLATE": "Añadir una plantilla",
  "MARK_GLOBAL": "Marcar como global",
  "UNMARK_GLOBAL": "Desmarcar como global",
  "MARK_GLOBAL_TOOLTIP": "Solo disponible como ADMIN de MoreApp. Haz click en 'Marcar como global' para que esté disponible públicamente en la pestaña \"Nuevo Formulario\".",
  "ADD_APPLICATION_PANEL_TITLE": "Añadir una carpeta",
  "ADD_APPLICATION_PANEL_TITLE_LABEL": "Nombre de la carpeta",
  "ADD_APPLICATION_PANEL_TITLE_PLACEHOLDER": "Nombre",
  "CUSTOMER_APPLICATIONS_DELETE_FORM_TITLE": "Eliminar formulario",
  "CUSTOMER_APPLICATIONS_DELETE_FORM_MESSAGE": "¿Estás seguro de que quieres eliminar este formulario?",
  "CUSTOMER_APPLICATIONS_COPY_FORM_TITLE": "Copiar formulario",
  "CUSTOMER_APPLICATIONS_COPY_FORM_MESSAGE": "Elegir una carpeta para copiar el formulario",
  "CUSTOMER_APPLICATIONS_COPY_FORM_DESTINATION_LABEL": "Carpeta",
  "CUSTOMER_APPLICATIONS_COPY_FORM_DESTINATION_PLACEHOLDER": "Carpeta",
  "CUSTOMER_APPLICATIONS_COPY_FORM_NAME_LABEL": "Nombre de la copia",
  "CUSTOMER_APPLICATIONS_ACTIONS_DEPLOY": "Publicar",
  "CUSTOMER_APPLICATIONS_ACTIONS_USER_MANAGEMENT": "Administrar usuarios",
  "CUSTOMER_APPLICATIONS_ACTIONS_COPY": "Copiar",
  "CUSTOMER_APPLICATIONS_ACTIONS_DELETE": "Eliminar",
  "CUSTOMER_APPLICATION_COPY_TITLE": "Copiar carpeta",
  "CUSTOMER_APPLICATION_CUSTOMER_LABEL": "Cliente",
  "CUSTOMER_APPLICATION_DEPLOY_TITLE": "Publicar carpeta",
  "CUSTOMER_APPLICATION_DEPLOY_QUESTION": "¿Estás seguro de que quieres publicar la carpeta?",
  "CUSTOMER_APPLICATION_DEPLOY_SUCCESS_MESSAGE": "¡Carpeta publicada con éxito!",
  "CUSTOMER_APPLICATION_DEPLOY_ERROR_MESSAGE": "Fallo al publicar la carpeta debido a motivos desconocidos. Por favor, consulta las propiedades de definición que faltan. Si no puedes resolver el problema, por favor, ponte en contacto con tu partner.",
  "CUSTOMER_APPLICATION_DEPLOY_VALIDATION_MESSAGE": "Fallo al publicar la carpeta. Por favor, arregla los siguientes errores y reinténtalo:",
  "CUSTOMER_APPLICATION_DEPLOY_LAST_VALIDATION_MESSAGE": "El intento anterior tenía los siguientes errores:",
  "CUSTOMER_APPLICATION_DEPLOY_SHOW_LAST_VALIDATION_BTN": "Mostrar errores previos",
  "CUSTOMER_APPLICATION_DEPLOY_BUTTON": "Publicar",
  "CUSTOMER_APPLICATION_PLACE_ON_MARKETPLACE_TITLE": "Poner en el Marketplace",
  "INVALID_APPLICATION_HEADER": "Errores de aplicación:",
  "INVALID_FORM_HEADER": "Problemas del formulario:",
  "INVALID_APPLICATION_START_PAGE": "La carpeta debe tener una página de inicio",
  "INVALID_APPLICATION_NAME": "La carpeta debe tener un nombre",
  "INVALID_APPLICATION_THEME": "La carpeta debe tener un tema",
  "INVALID_FORM_SEARCH_SETTINGS_ITEM_HTML": "Ajustes de búsqueda \"Item markup\" no es válido",
  "INVALID_VIEW_ITEM_INVALID_PROPERTIES": "\"{{fieldName}}\" tiene propiedades no válidas",
  "INVALID_VIEW_ITEM_INVALID_PROPERTY": "\"{{fieldName}}\" tiene una propiedad no válida: \"{{property}}\"",
  "INVALID_VIEW_ITEM_DATA_NAME": "\"{{fieldName}}\" tiene un nombre de datos no válido",
  "INVALID_VIEW_ITEM_WIDGET_UID": "\"{{fieldName}}\" widget no está instalado",
  "INVALID_VIEW_ITEM_VIEW_ID": "\"{{fieldName}}\" no hace referencia a una vista conocida",
  "INVALID_VIEW_ITEM_MAIL": "Placeholder no válido en la plantilla del email cerca de \"{{ syntaxError }}\"",
  "INVALID_VIEW_ITEM_MAIL_DYNAMIC_RECIPIENTS": "Destinatarios dinámicos no válidos en plantilla del email cerca de \"{{ syntaxError }}\"",
  "INVALID_VIEW_ITEM_MAIL_STATIC_RECIPIENTS": "Destinatario erróneo en el email: <code>{{ syntaxError }}</code>",
  "INVALID_VIEW_ITEM_RULE": "Regla no válida con el nombre de \"{{ fieldName }}\"",
  "CUSTOMER_APPLICATION_USER_MANAGEMENT_TITLE": "Gestión de Usuarios",
  "CUSTOMER_APPLICATION_USER_MANAGEMENT_USER_LABEL": "Usuarios que tienen acceso",
  "CUSTOMER_APPLICATION_USER_MANAGEMENT_NO_USERS": "Ningún usuario",
  "CUSTOMER_APPLICATION_USERS_COUNT_POSTFIX": "usuario",
  "CUSTOMER_APPLICATION_USERS_COUNT_POSTFIX_PLURAL": "usuarios",
  "CUSTOMER_APPLICATIONS_DELETED_PREFIX": "Eliminado ",
  "CUSTOMER_APPLICATION_GROUP_MANAGEMENT_GROUP_LABEL": "Grupos que tienen acceso",
  "CUSTOMER_APPLICATION_GROUP_MANAGEMENT_NO_GROUPS": "Ningún grupo",
  "FORM_USER_MANAGEMENT_DESCRIPTION": "Define quién puede acceder y utilizar este formulario",
  "FOLDER_USER_MANAGEMENT_DESCRIPTION": "Define quién puede añadir formularios a esta carpeta",
  "CONTACT": "Contacto",
  "ADDITIONAL_FEES_APPLY": "Se aplican costes adicionales",
  "CUSTOMER_CONTACT_VAT_NUMBER": "CIF",
  "CUSTOMER_CONTACT_COC_NUMBER": "CdC",
  "CUSTOMER_CONTACT_SUPPORT_TITLE": "Soporte",
  "CUSTOMER_CONTACT_SUPPORT_SUCCESS_MESSAGE": "Hemos recibido tu notificación y haremos la acción apropiada.",
  "CUSTOMER_CONTACT_SUPPORT_ERROR_MESSAGE": "Fallo al enviar tu notificación. No dudes en contactar con nosotros sobre este problema.",
  "BILLING_INFO": "Información de facturación",
  "CONTACT_INFO": "Información de contacto",
  "INVOICES": "Facturas",
  "COPY_COLLECTION_TO_CUSTOMER_SELECT_CUSTOMER_LABEL": "Cuenta",
  "COPY_COLLECTION_TO_CUSTOMER_SELECT_CUSTOMER_PLACEHOLDER": "Seleccionar cliente",
  "COLLECTION_REMOVE_MODAL_TITLE": "Eliminar carpeta",
  "COLLECTION_REMOVE_MODAL_MESSAGE": "¿Estás seguro que quieres eliminar esta carpeta? Esta acción no se puede deshacer",
  "COPY_FORM_MODAL_TITLE": "Copiar formulario",
  "COPY_FORM_MODAL_MESSAGE": "Elegir una carpeta donde copiar el formulario y, si se prefiere, cambiar nombre",
  "COPY_FORM_MODAL_NAME_LABEL": "Nombre",
  "COPY_FORM_MODAL_DESTINATION_COLLECTION_LABEL": "Carpeta objetivo",
  "COPY_FORM_MODAL_DESTINATION_COLLECTION_PLACEHOLDER": "Seleccionar la carpeta donde copiar el formulario",
  "TOOLTIP_COPY_FORM_MODAL_NAME": "Utiliza opcionalmente un nombre diferente para la copia.",
  "TOOLTIP_COPY_FORM_MODAL_DESTINATION_CUSTOMER": "La cuenta en la que se copia este formulario.",
  "TOOLTIP_COPY_FORM_MODAL_DESTINATION_COLLECTION": "La copia se colocará en esta carpeta.",
  "REMOVE_LIST_ITEM_MODAL_TITLE": "Eliminar vista",
  "REMOVE_LIST_ITEM_MODAL_MESSAGE": "¿Estás seguro que quieres eliminar esta vista?",
  "SUPPORT_TITLE": "Soporte",
  "SUPPORT_QUESTION": "¿Algo ha ido mal?",
  "SUPPORT_CONTACT_BY_EMAIL_MESSAGE": "¿Has encontrado un bug o alguna característica que falte, o tienes alguna pregunta? Por favor, mándanos un email a <a href=\"mailto:support@moreapp.com\">support@moreapp.com</a>. Por favor, facilítanos información detallada acerca del problema con los posibles pasos hechos para reproducirlos y que usuarios y/o característica va relacionada.",
  "SUPPORT_QUESTION_SUBTITLE": "¡Por favor, rellena el formulario de abajo para hacérnoslo saber!",
  "SUPPORT_APPLICATION_QUESTION": "¿Qué carpeta estabas utilizando?",
  "SUPPORT_APPLICATION_PLACEHOLDER": "Nombre/id de la carpeta",
  "SUPPORT_FORM_QUESTION": "¿Qué formulario estabas utilizando?",
  "SUPPORT_FORM_PLACEHOLDER": "Nombre del formulario",
  "SUPPORT_DEVICE_QUESTION": "¿Qué tipo de dispositivo estabas utilizando?",
  "SUPPORT_DEVICE_PLACEHOLDER": "Tipo / Versión del sistema",
  "SUPPORT_DESCRIPTION_QUESTION": "¿Algo más que creas que nos puede ayudar?",
  "SUPPORT_DESCRIPTION_PLACEHOLDER": "Descripción",
  "SUPPORT_EMAIL_QUESTION": "Si fuera necesario, ¿cómo podríamos contactar contigo?",
  "SUPPORT_EMAIL_PLACEHOLDER": "Email",
  "SUPPORT_SUCCESS_TITLE": "¡Muchas gracias!",
  "SUPPORT_SUCCESS_MESSAGE": "Hemos recibido tu notificación y haremos la acción apropiada.",
  "APPLICATION_EDITOR_TITLE_PREFIX": "Editar carpeta: ",
  "APPLICATION_EDITOR_SETTINGS_TITLE": "Ajustes",
  "APPLICATION_EDITOR_SETTINGS_NAME_LABEL": "Nombre",
  "APPLICATION_EDITOR_SETTINGS_NAME_PLACEHOLDER": "Poner nombre a la carpeta",
  "APPLICATION_EDITOR_SETTINGS_START_PAGE_LABEL": "Página de inicio",
  "APPLICATION_EDITOR_SETTINGS_THEME_LABEL": "Tema",
  "APPLICATION_EDITOR_SETTINGS_IMAGE_LABEL": "Imagen",
  "APPLICATION_EDITOR_VIEWS_TITLE": "Vistas",
  "APPLICATION_EDITOR_TO_LABEL": "A",
  "APPLICATION_EDITOR_CC_LABEL": "CC",
  "APPLICATION_EDITOR_BCC_LABEL": "BCC",
  "APPLICATION_EDITOR_DISABLE_ADVANCED_MODAL_TITLE": "Cambiar a modo simple",
  "APPLICATION_EDITOR_DISABLE_ADVANCED_MODAL_MESSAGE": "¿Seguro que deseas volver al modo simple? Esto significa que perderás tu diseño personalizado del PDF y volverás a nuestro diseño de PDF predeterminado.",
  "APPLICATION_EDITOR_NOTIFICATION_EMAIL_NAME_LABEL": "Nombre",
  "APPLICATION_EDITOR_NOTIFICATION_EMAIL_STATIC_RECIPIENTS_LABEL": "Destinatario estático",
  "APPLICATION_EDITOR_NOTIFICATION_EMAIL_DYNAMIC_RECIPIENTS_LABEL": "Destinatario dinámico",
  "APPLICATION_EDITOR_NOTIFICATION_EMAIL_SUBJECT_LABEL": "Asunto",
  "APPLICATION_EDITOR_NOTIFICATION_EMAIL_FROM_NAME_LABEL": "Nombre",
  "APPLICATION_EDITOR_NOTIFICATION_EMAIL_FROM_NAME_PLACEHOLDER": "Nombre del reminente",
  "APPLICATION_EDITOR_NOTIFICATION_EMAIL_REPLY_TO_LABEL": "Responder a",
  "APPLICATION_EDITOR_NOTIFICATION_EMAIL_REPLY_TO_PLACEHOLDER": "Enviar respuestas a esta dirección",
  "APPLICATION_EDITOR_NOTIFICATION_EMAIL_FROM_EMAIL_LABEL": "De email",
  "APPLICATION_EDITOR_NOTIFICATION_EMAIL_ADD_ATTACHMENTS_LABEL": "Restablecer pin adjunto",
  "APPLICATION_EDITOR_NOTIFICATION_EMAIL_RESET_ATTACHMENT_LABEL": "Restablecer documento adjunto",
  "APPLICATION_EDITOR_NOTIFICATION_EMAIL_RESET_ATTACHMENT_MODAL_TITLE": "¿Estás seguro de que quieres restablecer el PDF adjunto?",
  "APPLICATION_EDITOR_NOTIFICATION_EMAIL_RESET_ATTACHMENT_MODAL_MESSAGE": "Esto sobrescribirá los cambios de personalización y generará, de nuevo, el PDF basado en la configuración de su formulario actual.",
  "APPLICATION_EDITOR_NOTIFICATION_EMAIL_ALL_IMAGES": "Todas las imágenes",
  "APPLICATION_EDITOR_NOTIFICATION_EMAIL_ATTACH_PDF": "Adjuntar PDF con nombre:",
  "APPLICATION_EDITOR_NOTIFICATION_HEADER_AND_FOOTER": "Utilizar encabezamiento y pie en el PDF",
  "APPLICATION_EDITOR_NOTIFICATION_HEADER_AND_FOOTER_LOGO": "Con el logo:",
  "APPLICATION_EDITOR_NOTIFICATION_HIDE_NO_VALUES": "Ocultar los campos que no están rellenados",
  "APPLICATION_EDITOR_NOTIFICATION_HORIZONTAL_ORIENTATION": "Utilice la orientación horizontal para el subformulario",
  "APPLICATION_EDITOR_NOTIFICATION_PLACEHOLDERS_LABEL": "Marcadores de posición",
  "APPLICATION_EDITOR_NOTIFICATION_SHOW_MAP_LABEL": "Mostrar mapa",
  "APPLICATION_EDITOR_TOOLTIP_STATIC_RECIPIENTS": "Rellena uno o más direcciones de correo electrónico de quiénes les quieres enviar el formulario, separado por comas. <br><br><b>Ejemplo</b><br>alice@example.com, bob@example.com",
  "${sys.user}": "usuario",
  "VIEW_EDITOR_PUBLISH_FAILED_MODAL_TITLE": "Fallo al publicar",
  "VIEW_EDITOR_PUBLISH_FAILED_MODAL_MESSAGE": "El formulario se ha guardado pero no se ha podido publicar debido a algunos errores.",
  "VIEW_EDITOR_FORM": "Formulario",
  "VIEW_EDITOR_EMAIL": "Email",
  "VIEW_EDITOR_EMAIL_SUBTITLE": "Manda un email con un PDF en cada registro realizado",
  "VIEW_EDITOR_RULES": "Reglas",
  "VIEW_EDITOR_RULES_SUBTITLE": "Ocultar o mostrar campos bajo ciertas condiciones",
  "VIEW_EDITOR_SETTINGS": "Ajustes",
  "VIEW_EDITOR_SETTINGS_SUBTITLE": "Cambia la apariencia y comportamiento de tu formulario",
  "VIEW_EDITOR_SETTINGS_SEARCH_SUBTITLE": "Permite a los usuarios buscar registros desde la app y usarlos como punto de inicio para un nuevo registro",
  "VIEW_EDITOR_WIDGETS": "Widgets",
  "VIEW_EDITOR_HOOKS": "Integraciones",
  "VIEW_EDITOR_HOOKS_SUBTITLE": "Hace funcionar un sistema externo por cada registro",
  "VIEW_EDITOR_PROPERTIES": "Propiedades",
  "VIEW_EDITOR_PROPERTIES_INVALID_DATA_NAME": "El nombre de datos no es válido",
  "VIEW_EDITOR_DATA_NAME": "Nombre de datos",
  "VIEW_EDITOR_DUPLICATE_DATA_NAME_WARNING": "Este nombre de datos está duplicado, lo que podría dar lugar a un comportamiento inesperado",
  "VIEW_EDITOR_RULES_ADD_BUTTON": "Añadir regla",
  "VIEW_EDITOR_HOOKS_ADD_BUTTON": "Añadir Integración",
  "HOOK_SELECT_TITLE": "Seleccionar integración",
  "INTEGRATIONS_SUBTITLE": "Como alternativa, puedes realizar integraciones utilizando <a href='https://docs.moreapp.com/docs/developer-docs/ZG9jOjExMTkxNg-webhooks' target='_blank'>Webhooks</a>, nuestra <a href='https://docs.moreapp.com/docs/developer-docs/ZG9jOjQ2NDA2-introduction' target='_blank'>API</a>, o <a href='https://zapier.com/apps/moreapp/integrations' target='_blank'>Zapier</a>",
  "VIEW_EDITOR_HOOKS_NO_CONFIG_MESSAGE": "No hay nada que configurar para esta integración",
  "VIEW_EDITOR_HOOKS_VALID_HOOK_MESSAGE": "La configuración ha sido validada con éxito",
  "VIEW_EDITOR_HOOKS_REMOVE_MODAL_TITLE": "Eliminar Integración",
  "VIEW_EDITOR_HOOKS_REMOVE_MODAL_MESSAGE": "¿Seguro que quieres eliminar esta integración? Esta acción no se puede deshacer.",
  "VIEW_EDITOR_HOOKS_NO_HOOKS": "Actualmente no hay integraciones disponibles para añadir",
  "VIEW_EDITOR_NOT_VALIDATED_MESSAGE": "La configuración ha sido guardada, pero no pudo ser validada en este momento. Si el problema permanece, por favor, contacta con el autor de este integración: {{author}}",
  "VIEW_EDITOR_HOOKS_GET_MORE_HOOKS": "Obtener más integraciones",
  "VIEW_EDITOR_HOOKS_UNAVAILABLE_TOOLTIP": "Este integración no estará disponible hasta {{lastAvailableDate|date:'medium'}}. Puedes guardar la configuración pero no será validada. Además, el integración no será ejecutada hasta que esté de nuevo disponible.",
  "VIEW_EDITOR_RULE_NAME_LABEL": "Nombre",
  "VIEW_EDITOR_RULE_CONDITION_LABEL": "Condiciones",
  "VIEW_EDITOR_RULE_OTHER_TYPES": "Otros tipos",
  "VIEW_EDITOR_RULE_USER": "Nombre de usuario",
  "VIEW_EDITOR_RULE_CHOOSE_FIELD": "Elegir un campo",
  "VIEW_EDITOR_RULE_CONDITION_CHOOSE_A_CONDITION": "Elegir una condición",
  "VIEW_EDITOR_RULE_CONDITION_CHOOSE_A_VALUE": "Elegir un valor",
  "VIEW_EDITOR_RULE_CONDITION_STARTS_WITH": "Empieza con",
  "VIEW_EDITOR_RULE_CONDITION_ENDS_WITH": "Termina con",
  "VIEW_EDITOR_RULE_CONDITION_CONTAINS": "Contiene",
  "VIEW_EDITOR_RULE_CONDITION_GREATER_THAN": "Mayor que",
  "VIEW_EDITOR_RULE_CONDITION_LESS_THAN": "Menor que",
  "VIEW_EDITOR_RULE_CONDITION_IS": "es",
  "VIEW_EDITOR_RULE_CONDITION_HAS_VALUE": "Tiene valor",
  "VIEW_EDITOR_RULE_CONDITION_VALUE_PLACEHOLDER": "valor",
  "VIEW_EDITOR_RULE_ACTION_VALUE_PLACEHOLDER": "valor",
  "VIEW_EDITOR_RULE_CONDITION_CURRENT_FORM_FIELDS": "Campos actuales del formulario",
  "VIEW_EDITOR_RULE_CONDITION_OTHER_FORM_FIELDS": "Otros campos del formularios",
  "VIEW_EDITOR_RULE_ACTION_LABEL": "Acciones",
  "VIEW_EDITOR_RULE_ACTION_PREFIX": "Cuando",
  "VIEW_EDITOR_RULE_ACTION_POSTFIX": "de las condiciones coincidan, realizar lo siguiente",
  "VIEW_EDITOR_RULE_ACTION_CHOOSE_AN_ACTION_LABEL": "Elige una acción",
  "VIEW_EDITOR_VIEW_SETTINGS_TITLE": "Ajustes",
  "VIEW_EDITOR_SETTINGS_VIEW_NAME_LABEL": "Nombre",
  "VIEW_EDITOR_SETTINGS_VIEW_DESCRIPTION_LABEL": "Descripción",
  "VIEW_EDITOR_SETTINGS_FORM_ICON_LABEL": "Icono",
  "VIEW_EDITOR_SETTINGS_HIDDEN_IN_APP": "Ocultar en la aplicación",
  "VIEW_EDITOR_SETTINGS_ROOT_VIEW_LABEL": "Vista raíz",
  "VIEW_EDITOR_SETTINGS_ROOT_VIEW_LABEL_DESCRIPTION": "Este formulario debería mostrarse como vista raíz",
  "VIEW_EDITOR_SETTINGS_INTERACTION_LABEL": "Envío del formulario",
  "VIEW_EDITOR_SETTINGS_INTERACTION_IMMEDIATE_UPLOAD_LABEL": "Carga inmediata ",
  "VIEW_EDITOR_SETTINGS_INTERACTION_MANUAL_UPLOAD": "Carga manual",
  "VIEW_EDITOR_SETTINGS_ITEM_HTML": "Descripción en la app",
  "VIEW_EDITOR_SETTINGS_SAVE_MODE_LABEL": "Opción de envío",
  "VIEW_EDITOR_SETTINGS_SAVE_MODE_SAVE_AND_CLOSE_LABEL": "Guardar y salir (Por defecto)",
  "VIEW_EDITOR_SETTINGS_SAVE_MODE_ALL_LABEL": "Guardar y salir & Guardar y nuevo",
  "VIEW_EDITOR_SETTINGS_SAVE_MODE_NO_SAVE_LABEL": "No guardar (solo leer)",
  "VIEW_EDITOR_SETTINGS_SEARCH_SETTINGS_LABEL": "Ajustes de búsqueda",
  "VIEW_EDITOR_SETTINGS_SEARCH_SETTINGS_ENABLED": "Activado",
  "VIEW_EDITOR_SETTINGS_SEARCH_SETTINGS_ONLY_CURRENT_USER": "Mostrar únicamente registros realizados por el usuario actual",
  "VIEW_EDITOR_SETTINGS_SEARCH_SETTINGS_FILTERING_ENABLED": "Filtrado habilitado",
  "VIEW_EDITOR_SETTINGS_SEARCH_SETTINGS_FIELDS": "Campos",
  "VIEW_EDITOR_SETTINGS_SEARCH_SETTINGS_CHOOSE_FIELD": "Elegir campo",
  "VIEW_EDITOR_SETTINGS_FORM_PLACEHOLDER": "Formulario",
  "VIEW_EDITOR_SETTINGS_ITEM_HTML_LABEL": "Ítem markup",
  "ITEM_HTML_AVAILABLE_PARAMETERS": "Parámetros disponibles:",
  "VIEW_EDITOR_LOOKUP_VALUE_INVALID": "Este valor no existe",
  "VIEW_EDITOR_OPTION_ID_LABEL": "Valor",
  "VIEW_EDITOR_OPTION_EMAIL_LABEL": "Valor en el PDF",
  "VIEW_EDITOR_OPTION_VALUE_LABEL": "Valor",
  "VIEW_EDITOR_OPTION_ADD_BUTTON": "Añadir",
  "VIEW_EDITOR_OPTIONS_USE_IMAGES_AS_LABELS": "Usar imágenes como etiquetas",
  "VIEW_EDITOR_CUSTOMER_RESOURCE_LOOKUP_BUTTON_IMAGE": "Seleccionar imagen",
  "VIEW_EDITOR_CUSTOMER_RESOURCE_LOOKUP_BUTTON_RESOURCE": "Seleccionar imagen",
  "VIEW_EDITOR_CUSTOMER_RESOURCE_LOOKUP_BUTTON_ICON": "Seleccionar icono pin",
  "VIEW_EDITOR_URL_MODAL_TITLE_ICON": "Iconos pin por defecto",
  "VIEW_EDITOR_URL_BUTTON_TITLE_ICON": "Seleccionar icono pin por defecto",
  "VIEW_EDITOR_ADD_TO_FAVORITES": "Añadir a favoritos",
  "VIEW_EDITOR_ADVANCED_MODE": "Modo avanzado",
  "VIEW_EDITOR_WIDGET_TYPE_DEFAULT": "Por defecto",
  "VIEW_EDITOR_WIDGET_TYPE_INSTALLED": "Pro",
  "VIEW_EDITOR_WIDGET_TYPE_FAVORITE": "Favoritos",
  "VIEW_EDITOR_WIDGET_TYPE_FAVORITE_NO_WIDGETS": "Ningún favorito definido.",
  "VIEW_EDITOR_ADD_FAVORITE_WIDGET_TITLE": "Añadir Widget Favorito",
  "VIEW_EDITOR_ADD_FAVORITE_WIDGET_FAVORITE_NAME_LABEL": "Nombre del Favorito",
  "VIEW_EDITOR_ADD_FAVORITE_WIDGET_NAME_LABEL": "Nombre",
  "VIEW_EDITOR_ADD_FAVORITE_WIDGET_ORIGINAL_VALUE_LABEL": "Valor original",
  "VIEW_EDITOR_ADD_FAVORITE_WIDGET_FAVORITE_VALUE_LABEL": "Valor del Favorito",
  "VIEW_EDITOR_ADD_FAVORITE_NO_CHANGES": "No hay cambios en el widget existente.",
  "VIEW_EDITOR_ADD_EMAIL_BUTTON": "Añadir Email",
  "VIEW_EDITOR_DELETE_FAVORITE_WIDGET_TITLE": "Eliminar Widget",
  "VIEW_EDITOR_DELETE_FAVORITE_WIDGET_MESSAGE": "¿Estás seguro de que quieres eliminar este widget favorito?",
  "ERROR_SOMETHING_WENT_WRONG": "Algo fue mal.",
  "ERROR_SOMETHING_WENT_WRONG_MESSAGE": "Por favor, inténtalo de nuevo más tarde. Si el problema persiste, contáctanos.",
  "ACCESS_DENIED_MESSAGE": "Acceso denegado",
  "ACCESS_DENIED_DESCRIPTION": "La Plataforma es para Administradores. Contacta con el Titular de la Cuenta o inicia sesión en la <a href='{{webclientUrl}}'>App Web</a> para rellenar tus formularios.",
  "ACCESS_DENIED_LOGOUT": "Cerrar sesión",
  "ACCESS_DENIED_TO_LOGIN": "Ir a iniciar sesión",
  "PAGE_NOT_FOUND_TITLE": "Página no encontrada",
  "PAGE_NOT_FOUND_DESCRIPTION": "No se ha encontrado la página",
  "PAGE_NOT_FOUND_PLATFORM_OVERVIEW_LINK": "Volver a la Vista General de la Plataforma",
  "CUSTOMER_RESOURCES_TITLE": "Fuentes",
  "CUSTOMER_RESOURCES_UPLOAD_TYPE_TITLE": "Tipo",
  "CUSTOMER_RESOURCES_LIST_TITLE": "Galería",
  "CUSTOMER_RESOURCES_NAME_LABEL": "Nombre",
  "CUSTOMER_RESOURCES_UPLOAD_FILE_TITLE": "Cargar un documento",
  "CUSTOMER_RESOURCES_UPLOAD_FILE_LABEL": "Documento",
  "CUSTOMER_RESOURCES_UPLOAD_URL_TITLE": "Cargar desde URL",
  "CUSTOMER_RESOURCES_UPLOAD_URL_LABEL": "URL",
  "CUSTOMER_RESOURCES_REMOVE_MODAL_TITLE": "Eliminar fuente",
  "CUSTOMER_RESOURCES_REMOVE_MODAL_MESSAGE": "¿Estás seguro que quieres eliminar esta fuente? Esta acción no se puede deshacer.",
  "RESOURCES_UPLOAD_FAILURE": "Carga Fallida",
  "RESOURCES_UPLOAD_INVALID_FILE": "El documento seleccionado no es una imagen.",
  "RESOURCES_UPLOAD_FILE_TOO_LARGE": "El documento seleccionado sobrepasa el límite de 5MB.",
  "REGISTRATIONS": "Registros",
  "START": "Empezar",
  "CONFIGURE": "Configura",
  "UPGRADE": "Actualizar",
  "STEP": "Paso",
  "CREATED": "Creado",
  "CREATE": "Crear",
  "READ": "Mira",
  "DELETE": "Eliminar",
  "REMOVE": "Eliminar",
  "SUBMIT": "Enviar",
  "CANCEL": "Cancelar",
  "CLOSE": "Cerrar",
  "SEND": "Enviar",
  "SAVE": "Guardar",
  "RENAME": "Renombrar",
  "RENAME_LINKED_SUBFORM_TITLE": "Renombrar subformulario vinculado",
  "SAVE_PAYMENT_METHOD": "Confirmar y Utilizar este 'Método de Pago'",
  "SELECTED_PAYMENT_METHOD": "Seleccionar método de pago",
  "SELECT_OTHER_PAYMENT_METHOD": "Utilizar otro método de pago",
  "LOADING": "Cargando",
  "SAVE_AND_CLOSE": "Guardar & Cerrar",
  "SAVE_AND_PUBLISH": "Guardar & Publicar",
  "VALIDATE": "Validar",
  "EDIT": "Editar",
  "UNDO": "Deshacer",
  "BACK": "Atrás",
  "NEXT": "Siguiente",
  "PREVIOUS": "Previo",
  "CLEAR": "Borrar",
  "SELECT": "Seleccionar",
  "UPLOAD": "Cargar",
  "BUSY_UPLOADING": "Carga lenta...",
  "UPDATE": "Actualizar",
  "ADD": "Añadir",
  "COPY": "Copiar",
  "SHARE": "Compartir",
  "ACTIONS": "Operaciones",
  "THEME": "Tema",
  "ON": "On",
  "OFF": "Off",
  "OPTIONS": "Opciones",
  "INSTALLED": "Instalado",
  "EMPTY": "Vacío",
  "QUARTER_HOUR": "Cada 15 minutos",
  "HALF_HOUR": "Cada 30 minutos",
  "DAILY": "Diariamente",
  "WEEKLY": "Mensualmente",
  "HOURLY": "Cada hora",
  "API": "API",
  "NAME": "Nombre",
  "EMAIL": "Email",
  "DATE": "Fecha",
  "DUE_DATE": "Fecha de vencimiento",
  "HOOKS": "Integraciones",
  "NONE": "Ninguno",
  "NEVER": "Nunca",
  "USER": "Usuario",
  "GROUP": "Grupo",
  "VIA_GROUP": "a través de grupo(s)",
  "BETA": "beta",
  "LOCKED": "Bloqueado",
  "URL": "URL",
  "FILE": "Documento",
  "YES": "Sí",
  "NO": "No",
  "DEFAULT": "Por defecto",
  "SEARCH": "Buscar",
  "DETAILS": "Detalles",
  "UNSAVED_CHANGES_TITLE": "Cambios no guardados",
  "UNAVAILABLE": "No disponible",
  "VERSION": "Versión",
  "PRICE": "Precio",
  "FREE": "Gratis",
  "ADDRESS": "Dirección",
  "ADDRESS_LINE_1": "Dirección",
  "ADDRESS_LINE_1_PLACEHOLDER": "C/ Principal 123",
  "CITY": "Ciudad",
  "COUNTRY": "País",
  "ZIP_CODE": "Código postal",
  "OK": "OK",
  "INTERNAL_ERROR": "Error interno",
  "OPEN": "Abrir",
  "STALLED": "Estancado",
  "ERROR": "Error",
  "SUCCESS": "Éxito",
  "RETRY": "Inténtalo de nuevo",
  "SKIP": "Saltar",
  "IN_PROGRESS": "En progreso",
  "COMPLETED": "Terminado",
  "DECLINED": "Declinado",
  "BLOCKED": "Uno de los integraciones ha fallado",
  "NOT_VALIDATED": "No validado",
  "INVALID": "No válido",
  "VALID": "Válido",
  "VERIFIED": "Verificado",
  "UNVERIFIED": "No verificado",
  "TYPE": "Tipo",
  "VALUE": "Valor",
  "ONLINE": "Online",
  "OFFLINE": "Offline",
  "ADVANCED": "Avanzado",
  "PREVIEW": "Vista previa",
  "BY": "Por",
  "UNTIL": "Hasta",
  "COUPON": "Cupón",
  "COUPON_ADD_ERROR": "Cupón inválido",
  "DISCOUNT": "Descuento",
  "COUPON_REMOVE_TITLE": "Eliminar cupón",
  "COUPON_REMOVE_MESSAGE": "¿Seguro que quieres eliminar este cupón?",
  "PERMISSIONS": "Permisos",
  "THIS_MIGHT_TAKE_SOME_TIME": "Puede que tarde un poco",
  "FIELD_ERROR_REQUIRED": "Este campo es obligatorio",
  "FIELD_ERROR_MIN_LENGTH": "El campo tiene que tener al menos la longitud de {{minLength}} carácteres",
  "FIELD_ERROR_MAX_LENGTH": "El campo sobrepasa la longitud máxima {{maxLength}}",
  "FIELD_ERROR_MIN": "El valor es inferior al valor mínimo {{min}}",
  "FIELD_ERROR_MAX": "El valor es superior al valor máximo {{max}}",
  "FIELD_ERROR_EMAIL": "El valor no es una dirección email válida",
  "BILLING_BANK_ACCOUNT_NUMBER": "Cuenta Bancaria (IBAN)",
  "BILLING_VAT_NUMBER": "Número de identificación IVA",
  "BILLING_VAT_NUMBER_CHECKING": "Comprobación",
  "BILLING_VAT_NUMBER_VALID": "Válido",
  "BILLING_VAT_NUMBER_APPLIED_PERCENTAGE": "Porcentaje IVA",
  "BILLING_VAT_NUMBER_INVALID": "No válido",
  "BILLING_VAT_NUMBER_COUNTRY_MISMATCH": "País no coincide",
  "BILLING_VAT_NUMBER_COUNTRY_MISMATCH_MESSAGE": "El país de tu número de identificación IVA y el país seleccionado no coinciden. Por favor, arréglalo para que podamos procesar tu pedido.",
  "BILLING_COC_NUMBER": "Cámara de comercio",
  "BILLING_EMAIL": "Email de Facturación",
  "VAT_DISCLAIMER": "Los precios mostrados no incluyen IVA. El importe del IVA se mostrará después de procesar el pago y se mostrará en las facturas.",
  "RECEIVE_NEWS_LETTER": "Newsletter",
  "RECEIVE_NEWS_LETTER_MESSAGE": "Quiero recibir el newsletter",
  "TERMS_OF_CONDITION_DOWNLOAD": "Descargar los términos y condiciones",
  "FORM_BUILDER_SUB_FORM_PANEL_TITLE": "Subformulario",
  "FORM_BUILDER_NEW_SUB_FORM_NAME": "Nombre del nuevo formulario",
  "FORM_BUILDER_SUB_FORM_NAME": "Nombre del formulario",
  "TOOLTIP_ADD_APPLICATION": "Una carpeta contendrá tus formularios.<br><br>Cada formulario tiene sus propios ajustes, permisos de usuarios y puede ser publicado de modo individual.",
  "TOOLTIP_ADD_APPLICATION_NAME": "Este nombre se mostrará en la app.",
  "TOOLTIP_ADD_FORM": "Añadir un formulario para hacer registros en la app.<br><br>Cada formulario tiene un número de campos, reglas y sus propios ajustes.",
  "TOOLTIP_ADD_FORM_NAME": "Este nombre se mostrará en la app.",
  "TOOLTIP_COPY_FORM_NAME": "De manera opcional utiliza un nombre distinto para la copia.",
  "TOOLTIP_COPY_FORM_DESTINATION": "La copia se colocará en esta carpeta.",
  "TOOLTIP_DELETE_FORM": "<b>Esta acción es irreversible</b>.<br>Ten en cuenta que tus registros se mantendrán",
  "TOOLTIP_COMMON_APPLICATION_INFO": "<a>Arrastra</a> para cambiar el orden de tus formularios dentro de la carpeta.<br><br>",
  "TOOLTIP_APPLICATION_DELETED_MESSAGE": "La carpeta ha sido archivada. Revierte esta acción haciendo click en el botón <a>Deshacer</a>.",
  "TOOLTIP_APPLICATION_NOT_PUBLISHED_MESSAGE": "La carpeta ha sido creada, pero aún no publicada.<br><br>Si quieres ver esta carpeta en la app, la tendrás que <a>Publicar</a> primero.",
  "TOOLTIP_APP_MANAGE_USERS": "Especifica que grupos y/o usuarios verán esta carpeta en la app después de iniciar sesión.",
  "TOOLTIP_VIEW_SETTING_NAME": "Este nombre se mostrará en la app.",
  "TOOLTIP_VIEW_SETTING_ICON": "Este icono es utilizado en la app cuando el formulario se muestra en una lista",
  "TOOLTIP_VIEW_SETTING_IS_ROOT_VIEW": "Habilitar que se vea este formulario en la vista general de <a>Formularios</a>.",
  "TOOLTIP_VIEW_SETTING_INTERACTION": "Elegir como y cuando este formulario será cargado:<br><br><a>Cargar inmediatamente:</a><br>Cuando guardes el formulario, la app lo cargará instantáneamente (si hay conexión a internet).<br><br><a>Permitir edición con carga manual:</a><br>Después de guardar el formulario, puedes editar el formulario antes de subirlo al servidor.<br><br><a>Carga manual (por defecto):</a><br>Los formularios guardados están escondidos en el dispositivo, y se pueden cargar manualmente como lotes desde la pantalla \"data \".",
  "TOOLTIP_VIEW_SETTING_SAVE_MODE": "Elige el modo guardar:<br><br><a>Solo Guardar y Cerrar (por defecto):</a><br>Un simple botón de <b>Guardar</b> aparecerá, que guarda y cierra el formulario (cuando es válido).<br><br><a>Todos:</a><br>El botón de <b>Guardar</b> y <b>Guardar y Nuevo</b> aparece, para que puedas seguir añadiendo registros del mismo formulario.<br><br><a>No guardar:</a><br>Tener este formulario sólo para lectura. No aparece ningún botón.",
  "TOOLTIP_VIEW_SETTING_ITEM_HTML": "Esto define cómo cada registro, borrador o tarea de este formulario se muestra en la App. Utiliza placeholders de los campos del formulario para poder reconocer mejor cada elemento.",
  "TOOLTIP_VIEW_SETTING_SEARCH_SETTINGS": "Habilitar búsqueda en este formulario en la app.",
  "TOOLTIP_VIEW_SETTING_SEARCH_SETTINGS_ONLY_CURRENT_USER": "Habilitar búsqueda de solo los registros enviados por el usuario que está actualmente conectado.",
  "TOOLTIP_VIEW_SETTING_SEARCH_SETTINGS_FILTER": "Habilitar el filtrado de ciertos campos de formularios encontrados",
  "VIEW_EDITOR_SETTINGS_SEARCH_SETTINGS_FILTER_FIELD": "Excluir los siguientes valores en el resultado",
  "TOOLTIP_FORM_RULE_CONDITION": "Especifica una o más <a>Condiciones</a> que se tienen que cumplir para ejecutar las <a>Acciones</a>.<br><br>De esta manera, los campos irrelevantes o inaplicables se pueden omitir en determinadas condiciones.<br><br><b>Por ejemplo:</b><br>Esconder <a>Nombre del cónyuge</a> cuando <a>Estado civil</a> sea <a>\"soltero\"</a>",
  "TOOLTIP_FORM_RULE_ACTION": "Especifica una o más <a>Acciones</a> para que se ejecuten cuando todas las <a>Condiciones</a> se cumplan.",
  "TOOLTIP_PROPERTY_DATA_NAME": "Nombre que será utilizado en la columna nombre en <a>Cuadrícula de Registros</a>. Por defecto, este valor será rellenado automáticamente basado en el <b>texto de la etiqueta</b><br><br><b>Por ejemplo:</b><br>Si tu texto de la etiqueta es <a>\"¿Cuántos años tienes?\"</a>, el nombre de datos será <a>cuantosAñosTieneS</a>. Es posible que quieras cambiar esto a <a>\"años\"</a>.",
  "TOOLTIP_ADD_DATA_SOURCE": "Añadir base de datos",
  "TOOLTIP_ADD_DATA_SOURCE_NAME": "Nombre de la base de datos",
  "TOOLTIP_ADD_DATA_SOURCE_EXCEL_FILE": "Seleccionar un Excel para importar. Asegúrate de que la extensión del Excel sea `.xlsx`. Documentos `.xls` no serán importados.",
  "TOOLTIP_HOOK": "La configuración específica de este integraciónen este formulario",
  "TOOLTIP_DIRECT_FORM_LINK": "Crear una URL única de tu formulario, para que la puedas compartir con otros.<br><br>Utilizando esta URL, la gente que no tenga una cuenta MoreApp puede rellenar tu formulario.",
  "TOOLTIP_SHARE_FORM_MARKETPLACE": "Comparte tu formulario en nuestro Marketplace, así otros pueden utilizarlo como plantilla.",
  "RULE_HIDDEN": "Ocultar",
  "RULE_VISIBLE": "Visible",
  "RULE_GETS_VALUE": "Tiene valor",
  "WIDGET_LICENSE_INVALID": "Licencia no válida",
  "WIDGET_LICENSE_BRAND": "Marca",
  "WIDGET_LICENSE_TYPE": "Tipo",
  "WIDGET_LICENSE_POWER": "Power",
  "WIDGET_LICENSE_APK_EXPIRATION_DATE": "APK",
  "WIDGET_LICENSE_FIRST_INTERNATIONAL_ADMISSION": "Primera admisión",
  "WIDGET_LICENSE_EURO_CLASSIFICATION": "Euro class",
  "SEGMENT": "Sector",
  "SEGMENT_AUTOMOTIVE": "Automóvil",
  "SEGMENT_CONSTRUCTION": "Construcción",
  "SEGMENT_FACILITY": "Instalaciones",
  "SEGMENT_FINANCIAL": "Financiero",
  "SEGMENT_TRADE_AND_INDUSTRY": "Comercio e Industria",
  "SEGMENT_GOVERNMENT": "Gobierno",
  "SEGMENT_HEALTH_CARE": "Sanidad",
  "SEGMENT_INSTALLATION": "Instalación y Construcción",
  "SEGMENT_OTHER": "Otro",
  "GENERIC": "General",
  "FACILITY": "Instalaciones",
  "CONSTRUCTION": "Construcción",
  "SALES": "Ventas",
  "HR": "RRHH",
  "AUTOMOTIVE": "Automóvil",
  "SAFETY": "Seguridad y Protección",
  "LOGISTICS": "Logística",
  "INSTALLATION": "Instalación y Construcción",
  "MAINTENANCE": "Servicios y Mantenimiento",
  "SECURITY": "Seguridad",
  "REAL_ESTATE": "Inmobiliaria",
  "HEALTHCARE": "Salud",
  "AGRICULTURE": "Agricultura",
  "INDUSTRY": "Industria",
  "INSUFFICIENT_PERMISSIONS_TITLE": "Permisos insuficientes",
  "INSUFFICIENT_PERMISSIONS_MESSAGE": "Has iniciado sesión en la plataforma. Esta es una sección donde sólo tienen acceso los administradores de tu organización. Si tan sólo necesitas rellenar formularios, por favor, abre la app en tu smartphone o tablet.",
  "FORM_SUBMISSION_SERVER_ERROR": "El formulario no pudo ser enviado. Esto puede que sea un problema del servidor. Por favor, inténtelo de nuevo más tarde",
  "UNKNOWN_ERROR": "Ocurrió un error desconocido",
  "ALL": "todas",
  "ANY": "cualquiera",
  "GLOBAL_ERRORS_TITLE": "Errores de Validación",
  "USE_TEMPLATE": "Utilizar plantilla",
  "LANGUAGE_EN": "Inglés",
  "LANGUAGE_NL": "Holandés",
  "LANGUAGE_DE": "Alemán",
  "LANGUAGE_ES": "Español",
  "LANGUAGE_RU": "Ruso",
  "LANGUAGE_PT": "Portugués",
  "LANGUAGE_FR": "Francés",
  "LANGUAGE_IT": "Italiano",
  "LANGUAGE_CA": "Catalán",
  "UNSAVED_CHANGES": "Hay cambios no guardados que serán descartados si continúas",
  "CHANGE_FILE": "Seleccionar otro archivo",
  "AUTHENTICATED": "Autentificado",
  "LANGUAGE": "Idioma",
  "TAGS": "Tags",
  "SELECT_TAGS": "Seleccionar tags",
  "SELECT_ALL": "Seleccionar todo",
  "SEARCH_TEMPLATES": "Buscar plantillas",
  "CUSTOMER": "Cliente",
  "PARTNER": "Partner",
  "CUSTOM": "Personalizado",
  "AMOUNT": "Cantidad",
  "PHONE": "Número de teléfono",
  "REGION": "Región",
  "STATUS": "Estado",
  "ACTIVE": "Activo",
  "INACTIVE": "Inactivo",
  "DISABLED": "Inactivo",
  "ACCOUNT": "Cuenta",
  "DOCUMENTATION": "Centro de Ayuda",
  "COMPANY_NAME": "Nombre de la Empresa",
  "COMPANY": "Empresa",
  "SHOW_MORE": "Mostrar más",
  "WEBSITE": "Página web",
  "CLICK_HERE": "Haz clic aquí",
  "MAIL_NOTIFICATION": "Notificación email",
  "CHECKED": "Con check",
  "UNCHECKED": "Sin Check",
  "ACCOUNT_NAME": "Empresa",
  "ACCOUNT_NAME_PLACEHOLDER": "E.j. Empresa SL or Juan Diaz",
  "FEATURE_HOOKS": "Integraciones",
  "FEATURE_HOOKS_MESSAGE": "<h4>Los Integraciones se utilizan para procesar datos después de enviar formularios</h4><p>Configura un flujo de trabajo, envía archivos a OneDrive, envía datos a Google Sheets o Word</p><p>Esta característica está disponible cambiando al plan <b>Rama</b> .</p>",
  "FEATURE_MANUAL_DATASOURCES": "Importación de datos",
  "FEATURE_MANUAL_DATASOURCES_MESSAGE": "<h4>Importa datos usando Excel, Google Sheets o una URL (JSON)</h4><p>Esta característica está disponible cambiando al plan <b>Hoja</b> .</p>",
  "FEATURE_AUTOMATIC_DATASOURCES": "Importación de datos automática",
  "FEATURE_AUTOMATIC_DATASOURCES_MESSAGE": "<h4>Importa datos automáticamente añadiendo una nueva entrada a tu Google Sheet o URL</h4><p>Esta característica está disponible cambiando al plan <b>Rama</b> .</p>",
  "FEATUER_ADVANCED_PDF": "PDF personalizado",
  "FEATUER_ADVANCED_PDF_MESSAGE": "<h4>Diseña tu informe PDF con código HTML</h4><p>Esta característica está disponible cambiando al plan <b>Rama</b> .</p>",
  "FEATURE_ADVANCED_USER_MANAGEMENT": "Administración avanzada de usuarios",
  "FEATURE_ADVANCED_USER_MANAGEMENT_MESSAGE": "<h4>Da acceso a los grupos a ciertas partes de la Plataforma</h4><p>Esta característica está disponible cambiando al plan <b>Árbol</b> .</p>",
  "FEATURE_PDF_LOGO": "Tu logo en el PDF",
  "FEATURE_PDF_LOGO_MESSAGE": "<h4>Reemplaza el logo de MoreApp por el tuyo</h4><p>Esta característica está disponible cambiando al plan <b>Hoja</b> .</p>",
  "FEATURE_MULTIPLE_EMAIL": "Múltiples emails",
  "FEATURE_MULTIPLE_EMAIL_MESSAGE": "<h4>Añade varios emails a un formulario</h4><p>Esta característica está disponible cambiando al plan <b>Hoja</b> .</p>",
  "FEATURE_WIDGETS": "Widgets Pro",
  "FEATURE_WIDGETS_MESSAGE": "<h4>Comienza a usar más widgets, como los widgets Catalogue, Calculation o Rating</h4><p>Esta característica está disponible cambiando al plan <b>Hoja</b> .</p>",
  "FEATURE_SEARCH": "Función de búsqueda en la app",
  "FEATURE_SEARCH_MESSAGE": "<h4>Gracias a la función de búsqueda, buscar formularios rellenados es fácil</h4><p>La función de búsqueda te permite recuperar registros enviados previamente y usarlos para un nuevo registro</p><p>Esta característica está disponible cambiando al plan <b>Hoja</b> .</p>",
  "FEATURE_CUSTOM_TEMPLATES": "Plantillas personalizadas",
  "FEATURE_CUSTOM_TEMPLATES_MESSAGE": "<h4>Crea tus plantillas personalizadas y ahorra tiempo en la creación de nuevos formularios.</h4><p>Esta característica está disponible cambiando al plan <b>Árbol</b>.</p>",
  "FEATURE_WEBHOOKS": "Webhooks",
  "FEATURE_WEBHOOKS_MESSAGE": "<h4>Utiliza webhooks para suscribir a eventos en tu cuenta.</h4><p>En cada evento, enviamos un mensaje a la URL, que has configurado, para que puedas realizar una acción. Esto te permite implementar flujos de trabajo complejos.</p><p><b>Ejemplo</b>: Configura un webhook para que se active cada vez que se envía un formulario, para almacenar los datos del formulario en tu propia base de datos.</p><br><p>Esta característica está disponible cambiando al plan <b>Rama</b> .</p>",
  "SWITCH_PLAN_CTA": "Selecciona un nuevo Plan",
  "SWITCH_PLAN_CONTACT_OWNER": "Contactar Titular de la Cuenta",
  "SWITCH_PLAN_CONTACT_OWNER_MAIL_SUBJECT": "Solicitud de actualización de nuestra cuenta MoreApp para utilizar la función \"{{feature}}\"\n",
  "SERVICE_ACCOUNTS_SELECT_PLACEHOLDER": "Seleccionar una cuenta de servicio...",
  "SERVICE_ACCOUNTS_NEW_LINK": "Conectar",
  "SERVICE_ACCOUNTS_NEW_TEXT": "una nueva cuenta de servicio",
  "SERVICE_ACCOUNTS_NON_FOUND_PROVIDER": "Ninguna cuenta de servicio de '{{provider}}' configurada.",
  "FRESHCHAT_CONTENT_HEADERS_CHAT": "Envíanos un mensaje",
  "FRESHCHAT_CONTENT_HEADERS_FAQ_THANK_YOU": "¡Gracias por tu feedback!",
  "FRESHCHAT_CONTENT_HEADERS_CSAT_QUESTION": "¿Hemos resuelto tus dudas?",
  "FRESHCHAT_CONTENT_HEADERS_CSAT_YES_QUESTION": "¿Cómo calificarías la experiencia?",
  "FRESHCHAT_CONTENT_HEADERS_CSAT_NO_QUESTION": "¿Cómo podemos mejorar?",
  "FRESHCHAT_CONTENT_HEADERS_CSAT_THANKYOU": "¡Muchas gracias por tu feedback!",
  "FRESHCHAT_CONTENT_HEADERS_CSAT_RATE_HERE": "¡Introduce tu puntuación aquí!",
  "FRESHCHAT_CONTENT_PLACEHOLDERS_CSAT_REPLY": "Deja tus sugerencias aquí",
  "ADD_USER_OR_GROUP": "Añadir usuario o grupo",
  "ADD_USER_TO_GROUP": "Añadir usuario",
  "ADD_USER_TO_GROUP_PLACEHOLDER": "Seleccionar uno o múltiples usuarios",
  "SELECT_ROLE": "Seleccionar rol",
  "ALL_USERS_ADDED_TO_GROUP": "Ya has añadido todos los usuarios existentes a este grupo",
  "WHATS_NEW": "¿Qué novedades hay?",
  "WIDGET_LABEL": "Etiqueta",
  "WIDGET_LABEL_DESCRIPTION": "Utiliza el widget <b>Etiqueta</b> para mostrar un separador dentro de tu <a>formulario</a>.<br><br><b>Nota:</b><br>Esto no forma parte de los <a>datos del registro</a>.",
  "WIDGET_TEXT": "Texto",
  "WIDGET_TEXT_DESCRIPTION": "Utiliza el widget <b>Texto</b> para que el usuario rellene una sola línea de texto.",
  "WIDGET_TEXT_AREA": "Área de Texto",
  "WIDGET_TEXT_AREA_DESCRIPTION": "Utiliza el widget <b>Área de Texto</b> para que el usuario rellene frases completas",
  "WIDGET_NUMBER": "Número",
  "WIDGET_NUMBER_DESCRIPTION": "Utiliza el widget <b>Número</b> para que el usuario rellene un número entero.",
  "WIDGET_RADIO": "Radio",
  "WIDGET_RADIO_DESCRIPTION": "Utiliza el widget <b>Radio</b> para que el usuario pueda elegir una de las opciones predefinidas.<br><br><b>Por ejemplo:</b><br>Bien / Mal.",
  "WIDGET_CHECKBOX": "Checkbox",
  "WIDGET_CHECKBOX_DESCRIPTION": "Utiliza el widget <b>Checkbox</b> para que el usuario marque una propiedad como Verdadera o Falsa.",
  "WIDGET_PHOTO": "Foto",
  "WIDGET_PHOTO_DESCRIPTION": "Utiliza el widget <b>Foto</b> para que el usuario haga una foto con la <a>cámara</a> del dispositivo o añada una de la <a>galería</a>.",
  "WIDGET_SIGNATURE": "Firma",
  "WIDGET_SIGNATURE_DESCRIPTION": "Utiliza el widget <b>Firma</b> para que el usuario dibuje su firma.",
  "WIDGET_HEADER": "Título",
  "WIDGET_HEADER_DESCRIPTION": "Utiliza el widget <b>Título</b> para introducir títulos en las secciones de tu formulario.<br><br><b>Nota:</b><br>Esto no forma parte de los <a>datos del registro</a>.",
  "WIDGET_DATE": "Fecha",
  "WIDGET_DATE_DESCRIPTION": "Utiliza el widget <b>Fecha</b> para que el usuario indique una fecha.",
  "WIDGET_DATETIME": "Fecha y Hora",
  "WIDGET_DATETIME_DESCRIPTION": "Utiliza el widget <b>Fecha y Hora</b> para que el usuario indique la fecha y hora.",
  "WIDGET_TIME": "Hora",
  "WIDGET_TIME_DESCRIPTION": "Utiliza el widget <b>Hora</b> para que el usuario indique la hora.",
  "WIDGET_EMAIL": "Email",
  "WIDGET_EMAIL_DESCRIPTION": "Utiliza el widget <b>Email</b> para que el usuario introduzca su dirección de correo.<br><br><b>Nota:</b><br>Este campo se puede utilizar como <a>Destinatario Dinámico</a> en la <a>configuración Email</a>.",
  "WIDGET_PHONE": "Teléfono",
  "WIDGET_PHONE_DESCRIPTION": "Utiliza el widget <b>Teléfono</b> para que el usuario introduzca un número de teléfono.",
  "WIDGET_LOOKUP": "Desplegable",
  "WIDGET_LOOKUP_DESCRIPTION": "Utiliza el widget <b>Desplegable</b> para que el usuario seleccione uno o varios de los valores predefinidos",
  "WIDGET_LIST_ITEM": "List Item",
  "WIDGET_LIST_ITEM_DESCRIPTION": "Utiliza el widget <b>List Item</b> para crear un link a otro <a>formulario</a>.",
  "WIDGET_SUBFORM": "Subformulario",
  "WIDGET_SUBFORM_DESCRIPTION": "Utiliza el widget <b>Subformulario</b> para que el usuario pueda añadir <a>los mismos widgets varias veces</a> al formulario.<br><br><b>Por ejemplo:</b><br>Añadir múltiples <a>materiales utilizados, horas trabajadas o fotos </a> a un <a>envío</a>. También es posible enlazar subformularios y trabajar con subformularios en subformularios.",
  "WIDGET_BARCODE": "Código de Barras",
  "WIDGET_BARCODE_DESCRIPTION": "Utiliza el widget <b>Código de Barras</b> para que el usuario pueda escanear un código con la <a>cámara</a> de su dispositivo.<br><br><b>Nota:</b><br>Admite QR, UPC, EAN, CODE_39 y otros.",
  "WIDGET_SLIDER": "Slider",
  "WIDGET_SLIDER_DESCRIPTION": "Utiliza el widget <b>Slider</b> para que el usuario arrastre un deslizador para elegir un número entre los valores mínimo y máximo definidos.",
  "WIDGET_HTML": "HTML",
  "WIDGET_HTML_DESCRIPTION": "Utiliza el widget <b>HTML</b> para añadir personalizaciones al formulario. (Solo para usuarios con conocimientos técnicos)",
  "WIDGET_IMAGE": "Imagen",
  "WIDGET_IMAGE_DESCRIPTION": "Utiliza el widget <b>Imagen</b> para mostrar una imagen en el <a>formulario</a>.<br><br><b>Nota:</b><br>Esto no forma parte de los <a>datos del registro</a>. Si quieres que los usuarios añadan imágenes, utiliza el widget <a>Foto</a>.",
  "WIDGET_SEARCH": "Búsqueda",
  "WIDGET_SEARCH_DESCRIPTION": "Utiliza el widget <b>Búsqueda</b>, en combinación con las Bases de Datos, para permitir a los usuarios seleccionar datos importados. Por ejemplo, información de clientes, productos o empleados.",
  "WIDGET_RDW": "Matrícula (NL)",
  "WIDGET_RDW_DESCRIPTION": "Utiliza el widget <b>Matrícula (NL)</b> para que el usuario introduzca un número de matrícula. El widget mostrará información adicional de la matrícula. <b>Nota:</b> Solo para matrículas de los Países Bajos. Se requiere conexión Internet para añadir esa información adicional.",
  "WIDGET_PIN": "Pin",
  "WIDGET_PIN_DESCRIPTION": "Utiliza el widget <b>Pin</b> para que el usuario pueda añadir uno o más pines en una imagen/mapa determinada. Cada pin que se coloca abre un <a>subformulario</a> para que el usuario añada información adicional sobre el pin.",
  "WIDGET_HELP": "Ayuda",
  "WIDGET_HELP_DESCRIPTION": "El widget Ayuda te permite añadir información adicional a cualquier widget. Cuando un usuario haga clic en el widget Ayuda, aparecerá una ventana emergente en la que se mostrará información adicional.",
  "WIDGET_RATING": "Valoración",
  "WIDGET_RATING_DESCRIPTION": "El widget Valoración permite a los usuarios puntuar un tema determinado, con un número de estrellas. Es posible configurar una escala de valoración entre dos y diez.",
  "WIDGET_CATALOGUE": "Catálogo",
  "WIDGET_CATALOGUE_DESCRIPTION": "Crea tu propia aplicación de pedidos con el widget Catálogo. Llena un carrito con productos como lo harías en una tienda web. Utiliza una Base de Datos con las siguientes columnas obligatorias: id, name, priceExVat (en céntimos), description. Opcional: foto, miniatura y vatRate. La foto y la miniatura son URL; por lo tanto, solo se muestran si el dispositivo tiene conexión a Internet. Puedes añadir tantas columnas como desees a la Base de Datos. Estas serán visibles en la página de detalles del producto.",
  "WIDGET_SEARCH_WITH_GPS": "Búsqueda con GPS",
  "WIDGET_SEARCH_WITH_GPS_DESCRIPTION": "Búsqueda con localización GPS",
  "WIDGET_TIME_DIFFERENCE": "Diferencia de Tiempo",
  "WIDGET_TIME_DIFFERENCE_DESCRIPTION": "Este widget calcula la diferencia entre dos widgets Tiempo o widgets Fecha y Hora.",
  "WIDGET_PAYMENT": "Pago",
  "WIDGET_PAYMENT_DESCRIPTION": "Permite al usuario realizar una compra, dentro de la aplicación, para pagar el formulario",
  "WIDGET_STOPWATCH": "Cronómetro",
  "WIDGET_STOPWATCH_DESCRIPTION": "Utiliza el widget Cronómetro para controlar el tiempo en el formulario. El resultado de este widget será, por ejemplo, 00:16.47",
  "WIDGET_SMILEY": "Emoticono",
  "WIDGET_SMILEY_DESCRIPTION": "Widget para dar una calificación basada en emoticonos.",
  "WIDGET_CURRENT_LOCATION": "Ubicación Actual",
  "WIDGET_CURRENT_LOCATION_DESCRIPTION": "Este widget obtiene automáticamente tu ubicación actual utilizando el GPS. El resultado sería algo como \"Pinzon 12, 08003 Barcelona, España\". El resultado siempre se puede editar manualmente, en caso de que no sea correcto.",
  "WIDGET_DRAWING": "Dibujo",
  "WIDGET_DRAWING_DESCRIPTION": "Con este widget, puedes añadir dibujos, líneas y textos a una foto.",
  "WIDGET_VIDEO": "Vídeo",
  "WIDGET_VIDEO_DESCRIPTION": "Este widget te permitirá grabar o seleccionar un vídeo. Este widget creará una URL, en el informe PDF, que podrás utilizar para descargar el vídeo.",
  "WIDGET_ZIPCODE": "Código Postal",
  "WIDGET_ZIPCODE_DESCRIPTION": "El widget Código Postal te proporciona los datos de una dirección, a partir del código postal introducido",
  "WIDGET_IBAN": "IBAN",
  "WIDGET_IBAN_DESCRIPTION": "El widget IBAN valida números IBAN.",
  "WIDGET_DRAWING_FREE": "Dibujo Gratis",
  "WIDGET_DRAWING_FREE_DESCRIPTION": "Una copia del widget de dibujo, pero gratis. Para los clientes del partner Blauwdruk.",
  "WIDGET_CALCULATION": "Cálculo",
  "WIDGET_CALCULATION_DESCRIPTION": "El widget Cálculo permite realizar operaciones como suma, resta, multiplicación y división, con uno o varios widgets",
  "WIDGET_POSTCODE_NL": "Código Postal (NL)",
  "WIDGET_POSTCODE_NL_DESCRIPTION": "Este widget solo funciona con códigos postales holandeses. Con el widget Código Postal (NL) obtendrás direcciones basada en un código postal y un número de casa. Necesitas conexión a Internet para recibir los datos. Si no tienes conexión, puedes añadir los datos manualmente.",
  "WIDGET_ADVANCED_SEARCH": "Búsqueda Avanzada",
  "WIDGET_ADVANCED_SEARCH_DESCRIPTION": "Con el widget Búsqueda Avanzada puedes dar color a los resultados de la búsqueda en la app. Puedes definir los colores mediante descriptores (como \"rojo\" o \"verde\") o mediante códigos de color hexadecimales (como #1dc4c2)",
  "WIDGET_FILE": "Archivo",
  "WIDGET_FILE_DESCRIPTION": "Este widget te permitirá añadir archivos a tu formulario. Este widget creará una URL, en el informe PDF, para descargar el archivo.",
  "WIDGET_LOCATION": "Ubicación",
  "WIDGET_LOCATION_DESCRIPTION": "Este widget permite seleccionar una ubicación en un mapa.\n\nEl resultado siempre se puede editar manualmente, por si no es del todo correcto.",
  "WIDGET_PROPERTY_PIN_NAME": "Nombre",
  "WIDGET_PROPERTY_PIN_FORM": "Formulario",
  "WIDGET_PROPERTY_PIN_ICON": "Icono",
  "WIDGET_PROPERTY_PIN_ITEM_MARKUP": "Resumen en la app",
  "WIDGET_PROPERTY_PIN_TRACKING_ID": "ID de seguimiento",
  "WIDGET_PROPERTY_LABEL": "Etiqueta",
  "WIDGET_PROPERTY_LABEL_DESCRIPTION": "El nombre que se muestra encima del widget",
  "WIDGET_PROPERTY_OPERATOR": "Operador",
  "WIDGET_PROPERTY_OPERATOR_DESCRIPTION": "Operador utilizado en el cálculo",
  "WIDGET_PROPERTY_CALCULATION_TERMS": "Términos del Cálculo",
  "WIDGET_PROPERTY_CALCULATION_TERMS_DESCRIPTION": "Todos los campos que deben incluirse en el cálculo",
  "WIDGET_PROPERTY_INITIAL_VALUE": "Valor Inicial",
  "WIDGET_PROPERTY_INITIAL_VALUE_DESCRIPTION": "Valor inicial para el cálculo",
  "WIDGET_PROPERTY_DECIMAL_PRECISION": "Precisión Decimal",
  "WIDGET_PROPERTY_DECIMAL_PRECISION_DESCRIPTION": "El número de dígitos que tiene el cálculo",
  "WIDGET_PROPERTY_NO_VALUE_ON_MISSING_TERM": "Sin valor en término inválido",
  "WIDGET_PROPERTY_NO_VALUE_ON_MISSING_TERM_DESCRIPTION": "El widget no debe producir un valor cuando uno de los términos sea inválido (ej. no es un número).",
  "WIDGET_PROPERTY_CATALOGUE_DATA_SOURCE": "Base de Datos del Catálogo",
  "WIDGET_PROPERTY_CATALOGUE_DATA_SOURCE_DESCRIPTION": "Selecciona una de las bases de datos para utilizarla en el catálogo. La base de datos debe cumplir con la configuración requerida.",
  "WIDGET_PROPERTY_CURRENCY": "Moneda",
  "WIDGET_PROPERTY_CURRENCY_DESCRIPTION": "Configura la moneda a utilizar; pero solo si el valor es un precio",
  "WIDGET_PROPERTY_VAT_PERCENTAGE": "Porcentaje IVA",
  "WIDGET_PROPERTY_VAT_PERCENTAGE_DESCRIPTION": "El porcentaje de IVA que se aplicará a todos los artículos del catálogo",
  "WIDGET_PROPERTY_SHOW_PRICES": "Mostrar precios",
  "WIDGET_PROPERTY_SHOW_PRICES_DESCRIPTION": "Selecciona esta opción si para que los precios unitarios y totales se muestren en la app y en el PDF",
  "WIDGET_PROPERTY_SHOW_VAT": "Mostrar IVA",
  "WIDGET_PROPERTY_SHOW_VAT_DESCRIPTION": "Selecciona esta opción si para que los valores del IVA se muestren en la app y en el PDF",
  "WIDGET_PROPERTY_ALLOW_REMARKS": "Permitir observaciones",
  "WIDGET_PROPERTY_ALLOW_REMARKS_DESCRIPTION": "Selecciona esta opción para permitir a los usuarios que hagan un comentario al añadir un artículo",
  "WIDGET_PROPERTY_RATE_ICON": "Icono de valoración",
  "WIDGET_PROPERTY_RATE_ICON_DESCRIPTION": "Selecciona qué icono se utiliza para la valoración, por ejemplo, estrellas",
  "WIDGET_PROPERTY_RATE_SCALE": "Escala de valoración",
  "WIDGET_PROPERTY_RATE_SCALE_DESCRIPTION": "Selecciona la cantidad de iconos mostrados",
  "WIDGET_PROPERTY_TITLE": "Título",
  "WIDGET_PROPERTY_TITLE_DESCRIPTION": "El título de la explicación dada",
  "WIDGET_PROPERTY_EXPLANATION": "Explicación",
  "WIDGET_PROPERTY_EXPLANATION_DESCRIPTION": "La explicación",
  "WIDGET_PROPERTY_USE_ADDITIONAL_HELP_TEXT": "Utilizar el texto del botón de ayuda",
  "WIDGET_PROPERTY_USE_ADDITIONAL_HELP_TEXT_DESCRIPTION": "Selecciona esta opción para que el texto definido a continuación aparezca antes del signo de interrogación",
  "WIDGET_PROPERTY_ADDITIONAL_HELP_TEXT": "Texto del botón de ayuda",
  "WIDGET_PROPERTY_ADDITIONAL_HELP_TEXT_DESCRIPTION": "El texto que se muestra antes del signo de interrogación, si seleccionas la casilla anterior",
  "WIDGET_PROPERTY_THE_CALCULATED_VALUE_IS_A_PRICE": "El valor calculado es un precio",
  "WIDGET_PROPERTY_THE_CALCULATED_VALUE_IS_A_PRICE_DESCRIPTION": "Formatea el resultado como un precio",
  "WIDGET_PROPERTY_SKIP_HIDDEN_TERMS": "Ignorar widgets ocultos",
  "WIDGET_PROPERTY_SKIP_HIDDEN_TERMS_DESCRIPTION": "Este ajuste permite ignorar los widgets ocultos al realizar cálculos. Está disponible a partir de la versión 7.0.32.",
  "WIDGET_PROPERTY_USE_BARCODE_SCANNER": "Utilizar escáner de código de barras",
  "WIDGET_PROPERTY_USE_BARCODE_SCANNER_DESCRIPTION": "Selecciona para permitir a los usuarios buscar por un código de barras escaneado",
  "WIDGET_PROPERTY_DEFAULT_VALUE": "Valor predeterminado",
  "WIDGET_PROPERTY_DEFAULT_VALUE_DESCRIPTION": "Este valor ya está predefinido",
  "WIDGET_PROPERTY_DEFAULT_SEARCH_QUERY": "Consulta de Búsqueda predeterminada",
  "WIDGET_PROPERTY_DEFAULT_SEARCH_QUERY_DESCRIPTION": "Al abrir la búsqueda se utilizará esta consulta predeterminada.",
  "WIDGET_PROPERTY_LABEL_TEXT": "Etiqueta",
  "WIDGET_PROPERTY_START_TIME": "Hora de inicio",
  "WIDGET_PROPERTY_START_TIME_DESCRIPTION": "Hora de inicio para utilizar en el cálculo",
  "WIDGET_PROPERTY_END_TIME": "Hora de finalización",
  "WIDGET_PROPERTY_END_TIME_DESCRIPTION": "Hora de finalización para utilizar en el cálculo",
  "WIDGET_PROPERTY_BACKGROUND_IMAGE": "Imagen de fondo",
  "WIDGET_PROPERTY_MAXIMUM_DURATION_IN_SECONDS": "Duración máxima en segundos",
  "WIDGET_PROPERTY_MAXIMUM_DURATION_IN_SECONDS_DESCRIPTION": "La duración máxima del vídeo en segundos",
  "WIDGET_PROPERTY_VIDEO_QUALITY": "Calidad del Vídeo",
  "WIDGET_PROPERTY_VIDEO_QUALITY_DESCRIPTION": "Elige la calidad de vídeo que prefieras",
  "WIDGET_PROPERTY_ALLOWED_FILE_TYPE": "Tipo de Archivo",
  "WIDGET_PROPERTY_ALLOWED_FILE_TYPE_DESCRIPTION": "Selecciona el tipo de archivo que se puede cargar",
  "WIDGET_PROPERTY_VALUE": "Valor",
  "WIDGET_PROPERTY_PLACEHOLDER": "Valor de ejemplo",
  "WIDGET_PROPERTY_PLACEHOLDER_DESCRIPTION": "Valor de ejemplo que sólo se muestra cuando el campo está vacío y se elimina cuando se rellena",
  "WIDGET_PROPERTY_MAX_LENGTH": "Longitud máxima",
  "WIDGET_PROPERTY_MAX_LENGTH_DESCRIPTION": "Cantidad máxima de caracteres. Mostrará un error de validación en la app móvil si se supera este valor",
  "WIDGET_PROPERTY_MIN_LENGTH": "Longitud mínima",
  "WIDGET_PROPERTY_MIN_LENGTH_DESCRIPTION": "Cantidad mínima de caracteres. Mostrará un error de validación en la app móvil si la longitud de este valor es demasiado corta",
  "WIDGET_PROPERTY_MINIMUM_VALUE": "Valor mínimo",
  "WIDGET_PROPERTY_MINIMUM_VALUE_DESCRIPTION": "La app no permite elegir un valor inferior a este",
  "WIDGET_PROPERTY_MAXIMUM_VALUE": "Valor máximo",
  "WIDGET_PROPERTY_MAXIMUM_VALUE_DESCRIPTION": "La app no permite elegir un valor superior a este",
  "WIDGET_PROPERTY_USE_IMAGES_AS_LABELS": "Usar imágenes como etiquetas",
  "WIDGET_PROPERTY_OPTIONS": "Opciones",
  "WIDGET_PROPERTY_OPTIONS_DESCRIPTION": "Define las opciones que se pueden seleccionar en la app",
  "WIDGET_PROPERTY_VERTICAL_ALIGNMENT": "Alineación vertical",
  "WIDGET_PROPERTY_VERTICAL_ALIGNMENT_DESCRIPTION": "Selecciona para alinear las opciones verticalmente en lugar de horizontalmente",
  "WIDGET_PROPERTY_CHECKED_BY_DEFAULT": "Marcado por defecto",
  "WIDGET_PROPERTY_CHECKED_BY_DEFAULT_DESCRIPTION": "Si lo seleccionas, este campo se marcará cuando se abra un formulario nuevo",
  "WIDGET_PROPERTY_PHOTO_QUALITY": "Calidad de la foto",
  "WIDGET_PROPERTY_PHOTO_QUALITY_DESCRIPTION": "Elige la calidad de foto que prefieras.<br><br><a>Best possible (recomendada)</a><br>Sin reducción de escala, sin compresión. Podría causar problemas en el envío de emails con muchas fotos.<br><br><a>Fast Upload</a><br>Disminuye la escala a un máximo de 1024x1024, comprime un 75%. Podría causar problemas de memoria en los dispositivos de gama baja.<br><br><a>High Quality</a><br>Disminuye la escala a un máximo de 1920x1920, comprime un 10%. Podría causar problemas de memoria en dispositivos de gama baja y en el envío de emails con muchas fotos.",
  "WIDGET_PROPERTY_FILENAME": "Nombre del archivo",
  "WIDGET_PROPERTY_FILENAME_DESCRIPTION": "Elige el nombre de la foto que quieres que aparezca en la exportación",
  "WIDGET_PROPERTY_ALLOW_SELECTING_PHOTO_FROM_DEVICE": "Permitir selección de fotos desde el dispositivo",
  "WIDGET_PROPERTY_ALLOW_SELECTING_PHOTO_FROM_DEVICE_DESCRIPTION": "Permite a los usuarios seleccionar una foto hecha previamente desde el dispositivo",
  "WIDGET_PROPERTY_FULL_SIZE_PREVIEW": "Vista previa a tamaño completo",
  "WIDGET_PROPERTY_FULL_SIZE_PREVIEW_DESCRIPTION": "Selecciona esta opción si quieres mostrar una vista previa de la foto, a tamaño completo, en el formulario",
  "WIDGET_PROPERTY_SAVE_A_COPY_ON_THE_DEVICE": "Guardar una copia en el dispositivo",
  "WIDGET_PROPERTY_PENCIL_SIZE": "Tamaño del lápiz",
  "WIDGET_PROPERTY_PENCIL_SIZE_DESCRIPTION": "Aumentar/disminuir el tamaño del lápiz del usuario",
  "WIDGET_PROPERTY_WIDE": "Amplia",
  "WIDGET_PROPERTY_WIDE_DESCRIPTION": "Esto reducirá la altura permitida de una firma, haciendo que la firma resultante sea más ancha",
  "WIDGET_PROPERTY_TEXT": "Texto",
  "WIDGET_PROPERTY_HEADER_SIZE": "Tamaño del título",
  "WIDGET_PROPERTY_HEADER_SIZE_DESCRIPTION": "Tamaño del título, desde h1 (más grande) hasta h6 (más pequeño)",
  "WIDGET_PROPERTY_NOW_AS_DEFAULT": "Ahora por defecto",
  "WIDGET_PROPERTY_NOW_AS_DEFAULT_DESCRIPTION": "Selecciona para establecer automáticamente el valor de \"ahora\" cuando se abra el formulario",
  "WIDGET_PROPERTY_SORT_ALPHABETICALLY": "Ordenar alfabéticamente",
  "WIDGET_PROPERTY_SORT_ALPHABETICALLY_DESCRIPTION": "Selecciona para ordenar las opciones alfabéticamente",
  "WIDGET_PROPERTY_ALLOW_MULTIPLE_SELECTIONS": "Permitir múltiples selecciones",
  "WIDGET_PROPERTY_ALLOW_MULTIPLE_SELECTIONS_DESCRIPTION": "Selecciona para permitir la elección de múltiples opciones. Estas opciones se guardarán como valores separados por comas en el registro",
  "WIDGET_PROPERTY_EXPAND": "Expandir",
  "WIDGET_PROPERTY_EXPAND_DESCRIPTION": "Selecciona para expandir la lista al abrir el formulario",
  "WIDGET_PROPERTY_TARGET_VIEW": "Vista del objeto",
  "WIDGET_PROPERTY_TARGET_VIEW_DESCRIPTION": "Elige el formulario que deseas enlazar",
  "WIDGET_PROPERTY_IMAGE": "Imagen",
  "WIDGET_PROPERTY_IMAGE_DESCRIPTION": "Esta imagen se utilizará como miniatura en la app",
  "WIDGET_PROPERTY_ADD_BUTTON_TEXT": "Texto del botón",
  "WIDGET_PROPERTY_ADD_BUTTON_TEXT_DESCRIPTION": "Nombre del botón para añadir un elemento al subformulario",
  "WIDGET_PROPERTY_SUBFORM": "Subformulario",
  "WIDGET_PROPERTY_SUBFORM_DESCRIPTION": "Elige el formulario que deseas utilizar para crear tu objeto compuesto",
  "WIDGET_PROPERTY_LIST_ITEM_MARKUP": "Resumen en la aplicación",
  "WIDGET_PROPERTY_LIST_ITEM_MARKUP_DESCRIPTION": "Especifica cómo se muestra el elemento añadido, utilizando valores de campos específicos.<br><br>Por ejemplo:</b><br>Cada elemento añadido debe mostrarse como:<br> ${Nombre} ${Apellido}</b>",
  "WIDGET_PROPERTY_MINIMUM": "Mínimo",
  "WIDGET_PROPERTY_MINIMUM_DESCRIPTION": "La cantidad mínima de veces que se debe rellenar un subformulario",
  "WIDGET_PROPERTY_MAXIMUM": "Máximo",
  "WIDGET_PROPERTY_MAXIMUM_DESCRIPTION": "La cantidad máxima de veces que se debe rellenar un subformulario",
  "WIDGET_PROPERTY_STEP_SIZE": "Escalón",
  "WIDGET_PROPERTY_STEP_SIZE_DESCRIPTION": "Cuando se ajuste a 3, el deslizador siempre redondeará a un múltiplo de 3",
  "WIDGET_PROPERTY_HIDE_VALUE": "Ocultar el valor",
  "WIDGET_PROPERTY_HIDE_VALUE_DESCRIPTION": "Determina si se muestra o no el valor en la aplicación",
  "WIDGET_PROPERTY_HTML_CODE": "Código HTML",
  "WIDGET_PROPERTY_HTML_CODE_DESCRIPTION": "Añade un código HTML que soporte CSS en línea",
  "WIDGET_PROPERTY_RESOURCE": "Recurso",
  "WIDGET_PROPERTY_RESOURCE_DESCRIPTION": "Selecciona una imagen de la galería",
  "WIDGET_PROPERTY_MAXIMUM_WIDTH": "Anchura máxima",
  "WIDGET_PROPERTY_MAXIMUM_WIDTH_DESCRIPTION": "Ancho de la imagen dentro de la app. El ancho se limitará a la anchura de la pantalla si este valor lo supera",
  "WIDGET_PROPERTY_MAXIMUM_HEIGHT": "Altura máxima",
  "WIDGET_PROPERTY_MAXIMUM_HEIGHT_DESCRIPTION": "Altura de la imagen dentro de la app. La altura se limitará a la altura de la pantalla si este valor lo supera",
  "WIDGET_PROPERTY_DATA_SOURCE": "Base de datos",
  "WIDGET_PROPERTY_DATA_SOURCE_DESCRIPTION": "Selecciona una de tus bases de datos para buscar en ella. Se pueden crear nuevas bases de datos desde el menú principal, en la pestaña Datos",
  "WIDGET_PROPERTY_FILTER": "Filtro",
  "WIDGET_PROPERTY_FILTER_DESCRIPTION": "Filtra los resultados utilizando el valor de otros campos del formulario",
  "WIDGET_PROPERTY_LIST_COLORS": "Colores",
  "WIDGET_PROPERTY_LIST_COLORS_DESCRIPTION": "Aplica colores a las entradas de la base de datos que coincidan con los valores configurados",
  "WIDGET_PROPERTY_ALLOW_BARCODE_SCANNING": "Permitir escanear códigos de barras",
  "WIDGET_PROPERTY_ALLOW_BARCODE_SCANNING_DESCRIPTION": "Selecciona para permitir al usuario buscar en la base de datos utilizando el escáner de código de barras",
  "WIDGET_PROPERTY_REMEMBER_LAST_SEARCH_QUERY": "Recordar la última búsqueda",
  "WIDGET_PROPERTY_REMEMBER_LAST_SEARCH_QUERY_DESCRIPTION": "Selecciona para recordar la consulta de búsqueda. La consulta de búsqueda se ejecutará automáticamente",
  "WIDGET_PROPERTY_ALLOW_USER_TO_CHANGE_IMAGE": "Permitir al usuario cambiar de imagen",
  "WIDGET_PROPERTY_ALLOW_USER_TO_CHANGE_IMAGE_DESCRIPTION": "Selecciona para permitir a los usuarios utilizar sus propias imágenes como fondo para los pines, utilizando la cámara o la galería",
  "WIDGET_PROPERTY_PINS": "Pines",
  "WIDGET_PROPERTY_PINS_DESCRIPTION": "Define uno o más pines que pueden ser colocados en la imagen. Cada pin tiene su propio icono y abrirá el formulario especificado cuando se coloque.<br><br>En la app, los pines se numerarán automáticamente. Para utilizar tu propia numeración o etiquetado, define un ID de seguimiento.",
  "WIDGET_PROPERTY_REQUIRED": "Obligatorio",
  "WIDGET_PROPERTY_REQUIRED_DESCRIPTION": "Si lo seleccionas, este campo deberá ser rellenado para poder guardar el formulario",
  "WIDGET_PROPERTY_REMEMBER_INPUT": "Recordar entrada",
  "WIDGET_PROPERTY_REMEMBER_INPUT_DESCRIPTION": "Selecciona esta opción para recordar la última entrada del usuario, en este dispositivo, para el siguiente registro. Si también has establecido un valor por defecto, la opción de recordar la entrada será anulada.",
  "WIDGET_PROPERTY_DECIMAL_MARK": "Marca decimal",
  "WIDGET_PROPERTY_MINIMUM_AMOUNT": "Cantidad mínima",
  "WIDGET_PROPERTY_MINIMUM_AMOUNT_DESCRIPTION": "La app no permitirá elegir un valor inferior a este.",
  "WIDGET_PROPERTY_MAXIMUM_AMOUNT": "Cantidad máxima",
  "WIDGET_PROPERTY_MAXIMUM_AMOUNT_DESCRIPTION": "La app no permitirá elegir un valor superior a este.",
  "WIDGET_PROPERTY_CLICK_TO_VIEW": "Haz clic para ver la imagen",
  "WIDGET_PROPERTY_CLICK_TO_VIEW_DESCRIPTION": "Selecciona esta opción si quieres mostrar una vista previa de la foto, a tamaño completo, al hacer clic en ella.",
  "WIDGET_PROPERTY_DEFAULT_TO_CURRENT_LOCATION": "Ubicación actual por defecto",
  "INTEGRATION_TASK": "Tarea",
  "INTEGRATION_TASK_DESCRIPTION": "Esta integración permite crear una tarea de MoreApp basada en el registro entrante.",
  "INTEGRATION_MAILCHIMP": "Mailchimp",
  "INTEGRATION_MAILCHIMP_DESCRIPTION": "Esta integración permite enviar un correo usando Mailchimp. Puedes utilizar los datos del registro como placeholders en la plantilla de Mailchimp.",
  "INTEGRATION_DELETE": "Eliminar",
  "INTEGRATION_DELETE_DESCRIPTION": "Esta integración permite eliminar automáticamente los envíos de un formulario. Asegúrate de que esta es la última integración que vas a añadir a tu formulario. Una vez que un envío es eliminado no puede ser restaurado. Ten cuidado cuando uses esta integración.",
  "INTEGRATION_WORD": "Word",
  "INTEGRATION_WORD_DESCRIPTION": "Esta integración permite utilizar Microsoft Word para exportar datos. Puedes enviar archivos adjuntos en PDF o Word con esta integración. Puedes cargar una plantilla Word.docx para personalizar los informes.",
  "INTEGRATION_WEBDAV": "WebDAV",
  "INTEGRATION_WEBDAV_DESCRIPTION": "La integración WebDAV permite enviar el PDF desde un correo electrónico a un servidor WebDAV.",
  "INTEGRATION_GOOGLE_SHEETS": "Google Sheets",
  "INTEGRATION_GOOGLE_SHEETS_DESCRIPTION": "Esta integración permite enviar los formularios rellenados a una hoja de cálculo de Google. Puedes obtener el ID de tu hoja de cálculo de Google desde la URL. Nota: Los formularios enviados simplemente se añadirán como una nueva fila en la hoja. No actualizamos los encabezados de la hoja cada vez que actualizas tu formulario; así que tendrás que hacerlo manualmente. No se admiten imágenes/archivos. Todos los datos anidados (subformularios, formularios de pines, widget Búsqueda) se añadirán a una sola celda. En formularios con reglas y campos vacíos, los datos pueden colocarse en la columna equivocada.",
  "INTEGRATION_MONGODB_INSERT": "MongoDB Insert",
  "INTEGRATION_MONGODB_INSERT_DESCRIPTION": "La integración MongoDB Insert permite insertar los datos de tus registros en una base de datos Mongo.",
  "INTEGRATION_ONEDRIVE": "OneDrive",
  "INTEGRATION_ONEDRIVE_DESCRIPTION": "Esta integración permite enviar el PDF adjunto al formulario a Microsoft OneDrive.",
  "INTEGRATION_EXCEL": "Excel",
  "INTEGRATION_EXCEL_DESCRIPTION": "Esta integración permite utilizar Excel para exportar datos. Carga tu plantilla Excel.xlsx y recibe los datos en un archivo Excel.",
  "INTEGRATION_GOOGLE_SHEETS_CELL_UPDATE": "Google Sheets Cell Update",
  "INTEGRATION_GOOGLE_SHEETS_CELL_UPDATE_DESCRIPTION": "Actualiza una celda específica en una hoja existente. Esta integración permite actualizar una hoja que también se utiliza como base de datos importada en MoreApp.",
  "INTEGRATION_MESSAGE_BOARD": "Message Board",
  "INTEGRATION_MESSAGE_BOARD_DESCRIPTION": "Permite el envío de mensajes",
  "INTEGRATION_GOOGLE_SHEET_ACCEPT": "Google Sheet Accept",
  "INTEGRATION_GOOGLE_SHEET_ACCEPT_DESCRIPTION": "No para producción",
  "INTEGRATION_PROPERTY_FOLDER": "Carpeta",
  "INTEGRATION_PROPERTY_FOLDER_DESCRIPTION": "La carpeta a la que debe enviarse una tarea.",
  "INTEGRATION_PROPERTY_FORM": "Formulario",
  "INTEGRATION_PROPERTY_FORM_DESCRIPTION": "El formulario (dentro de la carpeta seleccionada) que debe utilizarse como objetivo para la tarea.",
  "INTEGRATION_PROPERTY_RECIPIENTS_COMMA_SEPARATED_LIST_OF_EMAILS": "Destinatarios (lista de emails separados por comas)",
  "INTEGRATION_PROPERTY_RECIPIENTS_COMMA_SEPARATED_LIST_OF_EMAILS_DESCRIPTION": "Destinatarios (lista de emails separados por comas)",
  "INTEGRATION_PROPERTY_DYNAMIC_RECIPIENTS": "Destinatarios dinámicos",
  "INTEGRATION_PROPERTY_DYNAMIC_RECIPIENTS_DESCRIPTION": "Utilizar los datos de un widget Email como destinatario",
  "INTEGRATION_PROPERTY_MESSAGE_FOR_THE_TASK": "Mensaje para la tarea",
  "INTEGRATION_PROPERTY_MESSAGE_FOR_THE_TASK_DESCRIPTION": "Mensaje para la tarea",
  "INTEGRATION_PROPERTY_DELAY_IN_DAYS": "Retraso (en días)",
  "INTEGRATION_PROPERTY_DELAY_IN_DAYS_DESCRIPTION": "La cantidad de días que el registro debe persistir antes de ser eliminado",
  "INTEGRATION_PROPERTY_OUTPUT_FORMAT": "Formato de salida",
  "INTEGRATION_PROPERTY_OUTPUT_FORMAT_DESCRIPTION": "Configura el formato de salida que se utilizará como archivo adjunto",
  "INTEGRATION_PROPERTY_REPLY_TO_OPTIONAL": "Responder a (opcional)",
  "INTEGRATION_PROPERTY_REPLY_TO_OPTIONAL_DESCRIPTION": "La dirección a la que responderán los destinatarios",
  "INTEGRATION_PROPERTY_FROM_OPTIONAL": "Desde (opcional)",
  "INTEGRATION_PROPERTY_FROM_OPTIONAL_DESCRIPTION": "El nombre desde el que quieres que se originen los emails",
  "INTEGRATION_PROPERTY_RECIPIENTS": "Destinatarios",
  "INTEGRATION_PROPERTY_RECIPIENTS_DESCRIPTION": "Lista de destinatarios separada por comas",
  "INTEGRATION_PROPERTY_SEND_TO_USER_THAT_SUBMITTED_THE_FORM": "Enviar al usuario que envió el formulario",
  "INTEGRATION_PROPERTY_SEND_TO_USER_THAT_SUBMITTED_THE_FORM_DESCRIPTION": "Selecciona esta opción para añadir el usuario que envía el formulario como destinatario",
  "INTEGRATION_PROPERTY_CC": "CC",
  "INTEGRATION_PROPERTY_CC_DESCRIPTION": "Lista de destinatarios separada por comas",
  "INTEGRATION_PROPERTY_BCC": "BCC",
  "INTEGRATION_PROPERTY_BCC_DESCRIPTION": "Lista de destinatarios separada por comas",
  "INTEGRATION_PROPERTY_SUBJECT": "Asunto",
  "INTEGRATION_PROPERTY_SUBJECT_DESCRIPTION": "Asunto con placeholders opcionales",
  "INTEGRATION_PROPERTY_BODY": "Cuerpo del email",
  "INTEGRATION_PROPERTY_BODY_DESCRIPTION": "Cuerpo del email con placeholders opcionales",
  "INTEGRATION_PROPERTY_IMAGE_QUALITY": "Calidad de la imagen",
  "INTEGRATION_PROPERTY_IMAGE_QUALITY_DESCRIPTION": "Si tu registro tiene muchas fotos de alta resolución, es posible que quieras bajar la calidad. De lo contrario, el email podría ser demasiado grande para el buzón.",
  "INTEGRATION_PROPERTY_FILENAME": "Nombre del archivo",
  "INTEGRATION_PROPERTY_FILENAME_DESCRIPTION": "Nombre del archivo con placeholders opcionales",
  "INTEGRATION_PROPERTY_UPLOAD_A_WORD_TEMPLATE": "Subir una plantilla de Word",
  "INTEGRATION_PROPERTY_UPLOAD_A_WORD_TEMPLATE_DESCRIPTION": "La plantilla de Word a utilizar",
  "INTEGRATION_PROPERTY_SERVER": "Servidor",
  "INTEGRATION_PROPERTY_SERVER_DESCRIPTION": "URL al servidor. Se utilizará como ruta base. Por ejemplo: http://my.server.com",
  "INTEGRATION_PROPERTY_PATH": "Ruta",
  "INTEGRATION_PROPERTY_PATH_DESCRIPTION": "Ruta donde se deben almacenar los PDF. Puede incluir placeholders, por ejemplo: /drive/${number}/",
  "INTEGRATION_PROPERTY_WEBDAV_USERNAME": "Nombre de usuario WebDAV",
  "INTEGRATION_PROPERTY_WEBDAV_USERNAME_DESCRIPTION": "Nombre de usuario a utilizar cuando se autentique en el servidor WebDAV",
  "INTEGRATION_PROPERTY_WEBDAV_PASSWORD": "Contraseña WebDAV",
  "INTEGRATION_PROPERTY_WEBDAV_PASSWORD_DESCRIPTION": "Contraseña a utilizar cuando se autentique en el servidor WebDAV",
  "INTEGRATION_PROPERTY_GOOGLE_LOGIN": "Acceso a Google",
  "INTEGRATION_PROPERTY_GOOGLE_LOGIN_DESCRIPTION": "Elige una cuenta de Google para permitirnos escribir en tus hojas de cálculo de Google",
  "INTEGRATION_PROPERTY_SPREADSHEET_ID": "Spreadsheet ID",
  "INTEGRATION_PROPERTY_SPREADSHEET_ID_DESCRIPTION": "El ID único de la hoja de cálculo que se muestra en la URL como https://docs.google.com/spreadsheets/d/<b>my-spreadsheet-id</b>.",
  "INTEGRATION_PROPERTY_SHEET_NAME": "Nombre de la hoja",
  "INTEGRATION_PROPERTY_SHEET_NAME_DESCRIPTION": "El nombre de la hoja; que suele ser, por defecto, 'Hoja1'",
  "INTEGRATION_PROPERTY_METADATA": "Metadatos",
  "INTEGRATION_PROPERTY_METADATA_DESCRIPTION": "Selecciona los campos meta que deseas incluir en la exportación. Estos se insertarán antes de los datos del formulario",
  "INTEGRATION_PROPERTY_MONGODB_HOST": "MongoDB Host",
  "INTEGRATION_PROPERTY_MONGODB_HOST_DESCRIPTION": "La dirección IP del host de MongoDB",
  "INTEGRATION_PROPERTY_MONGODB_PORT": "MongoDB Port",
  "INTEGRATION_PROPERTY_MONGODB_PORT_DESCRIPTION": "El puerto en el que MongoDB está conectado",
  "INTEGRATION_PROPERTY_USE_SSL": "Utilizar SSL",
  "INTEGRATION_PROPERTY_USE_SSL_DESCRIPTION": "Utilizar una conexión segura TLS",
  "INTEGRATION_PROPERTY_USERNAME": "Nombre de usuario",
  "INTEGRATION_PROPERTY_USERNAME_DESCRIPTION": "El nombre de usuario del usuario de Mongo con el que debemos autenticarnos",
  "INTEGRATION_PROPERTY_PASSWORD": "Contraseña",
  "INTEGRATION_PROPERTY_PASSWORD_DESCRIPTION": "La contraseña del usuario de Mongo con el que debemos autenticarnos",
  "INTEGRATION_PROPERTY_DATABASE": "Base de datos",
  "INTEGRATION_PROPERTY_DATABASE_DESCRIPTION": "La base de datos en la que quieres guardar los registros",
  "INTEGRATION_PROPERTY_COLLECTION": "Colección",
  "INTEGRATION_PROPERTY_COLLECTION_DESCRIPTION": "La colección en la que quieres guardar los registros",
  "INTEGRATION_PROPERTY_SEARCH_WIDGET": "Widget-Búsqueda",
  "INTEGRATION_PROPERTY_SEARCH_WIDGET_DESCRIPTION": "El widget-Búsqueda",
  "INTEGRATION_PROPERTY_ONEDRIVE_LOGIN": "Acceso a OneDrive",
  "INTEGRATION_PROPERTY_ONEDRIVE_LOGIN_DESCRIPTION": "Elige una cuenta de OneDrive para poder guardar los archivos",
  "INTEGRATION_PROPERTY_INCLUDE_CSV_FILE": "Incluir archivo CSV",
  "INTEGRATION_PROPERTY_INCLUDE_CSV_FILE_DESCRIPTION": "Añade automáticamente un archivo CSV (Comma Separated Value) a tu directorio de OneDrive",
  "INTEGRATION_PROPERTY_UPLOAD_AN_EXCEL_TEMPLATE": "Subir una plantilla de Excel",
  "INTEGRATION_PROPERTY_UPLOAD_AN_EXCEL_TEMPLATE_DESCRIPTION": "La plantilla de Excel a utilizar",
  "INTEGRATION_PROPERTY_EXCEL_FILE_NAME": "Nombre del archivo Excel",
  "INTEGRATION_PROPERTY_EXCEL_FILE_NAME_DESCRIPTION": "Nombre del archivo Excel como debe adjuntarse",
  "INTEGRATION_PROPERTY_COPY_TO_USER": "Copia al usuario",
  "INTEGRATION_PROPERTY_COPY_TO_USER_DESCRIPTION": "Envía una copia al usuario que rellena el formulario",
  "INTEGRATION_PROPERTY_ATTACH_IMAGES": "Adjuntar imágenes",
  "INTEGRATION_PROPERTY_ATTACH_IMAGES_DESCRIPTION": "Adjunta imágenes y firmas al email",
  "INTEGRATION_PROPERTY_NEW_VALUE_WIDGET": "Widget de valor nuevo",
  "INTEGRATION_PROPERTY_NEW_VALUE_WIDGET_DESCRIPTION": "Selecciona el widget del que quieres obtener el nuevo valor",
  "INTEGRATION_PROPERTY_COLUMN_NAME_TO_UPDATE": "Nombre de la columna a actualizar",
  "INTEGRATION_PROPERTY_COLUMN_NAME_TO_UPDATE_DESCRIPTION": "Define la columna que deseas actualizar con el valor del \"widget de valor nuevo\" seleccionado",
  "INTEGRATION_FTPS": "FTPS",
  "INTEGRATION_FTPS_DESCRIPTION": "La integración FTPS guarda los datos del registro en el servidor FTPS proporcionado.",
  "INTEGRATION_PROPERTY_PROTOCOL": "Protocolo",
  "INTEGRATION_PROPERTY_PROTOCOL_DESCRIPTION": "El protocolo a utilizar cuando se conecta al servidor FTP",
  "INTEGRATION_PROPERTY_DISABLE_SSL_VERIFICATION": "Desactivar la verificación SSL",
  "INTEGRATION_PROPERTY_DISABLE_SSL_VERIFICATION_DESCRIPTION": "Puedes utilizar esta opción cuando tengas un certificado autofirmado o una cadena de certificados no válida (solo aplicable al utilizar FTPS)",
  "INTEGRATION_PROPERTY_USE_IMPLICIT_FTPS_MODE": "Utilizar el modo FTPS 'implícito'",
  "INTEGRATION_PROPERTY_USE_IMPLICIT_FTPS_MODE_DESCRIPTION": "Cambiar entre el modo 'implícito' y 'explícito' de FTPS (solo aplicable cuando se utiliza FTPS)",
  "INTEGRATION_PROPERTY_FTP_SERVER_DESCRIPTION": "URL al servidor sin el protocolo. Se utilizará como ruta base. Por ejemplo: my.server.com",
  "INTEGRATION_PROPERTY_PORT": "Puerto",
  "INTEGRATION_PROPERTY_PORT_DESCRIPTION": "Puerto del servidor FTP(S). Por defecto será el 21 o el 990 si no se rellena.",
  "INTEGRATION_PROPERTY_PUT_EACH_REGISTRATION_IN_ITS_OWN_FOLDER": "Poner cada registro en su propia carpeta",
  "INTEGRATION_PROPERTY_PUT_EACH_REGISTRATION_IN_ITS_OWN_FOLDER_DESCRIPTION": "Añade automáticamente '/customerId/form-name/serial-number' a la ruta remota para crear una ruta única por cada registro",
  "INTEGRATION_PROPERTY_FTP_USERNAME": "Nombre de usuario FTP",
  "INTEGRATION_PROPERTY_FTP_USERNAME_DESCRIPTION": "Nombre de usuario a utilizar cuando se autentique en el servidor FTP",
  "INTEGRATION_PROPERTY_FTP_PASSWORD": "Contraseña FTP",
  "INTEGRATION_PROPERTY_FTP_PASSWORD_DESCRIPTION": "Contraseña a utilizar cuando se autentique en el servidor FTP",
  "INTEGRATION_PROPERTY_INCLUDE_PDF": "Incluir PDF",
  "INTEGRATION_PROPERTY_INCLUDE_PDF_DESCRIPTION": "Indica si se debe copiar también el PDF",
  "INTEGRATION_PROPERTY_STORE_PDF_FOR_MAILS_WITHOUT_RECIPIENT": "Almacenar el PDF de los emails sin destinatario",
  "INTEGRATION_PROPERTY_STORE_PDF_FOR_MAILS_WITHOUT_RECIPIENT_DESCRIPTION": "Indica si se almacenan o no los PDF que se generan para los emails sin destinatario",
  "INTEGRATION_PROPERTY_INCLUDE_FILES": "Incluir archivos",
  "INTEGRATION_PROPERTY_INCLUDE_FILES_DESCRIPTION": "Indica si se deben copiar también los archivos",
  "INTEGRATION_EXCEL_PROPERTY_TEMPLATE": "Sube una plantilla de Excel",
  "INTEGRATION_EXCEL_PROPERTY_TEMPLATE_DESCRIPTION": "La plantilla Excel que se utilizará",
  "INTEGRATION_EXCEL_PROPERTY_LEGACY_DELIMITERS": "Utilizar configuración anterior",
  "INTEGRATION_EXCEL_PROPERTY_LEGACY_DELIMITERS_DESCRIPTION": "Selecciona esta opción si deseas utilizar '${' como delimitador inicial para los placeholders. Esto solo se recomienda para plantillas existentes más antiguas.",
  "INTEGRATION_EXCEL_PROPERTY_FILENAME": "Nombre del archivo Excel",
  "INTEGRATION_EXCEL_PROPERTY_FILENAME_DESCRIPTION": "Nombre del archivo Excel que recibirás adjunto",
  "INTEGRATION_EXCEL_PROPERTY_RECIPIENTS": "Destinatarios",
  "INTEGRATION_EXCEL_PROPERTY_RECIPIENTS_DESCRIPTION": "Lista de destinatarios separados por comas.",
  "INTEGRATION_EXCEL_PROPERTY_DYNAMIC_RECIPIENTS": "Destinatarios dinámicos",
  "INTEGRATION_EXCEL_PROPERTY_DYNAMIC_RECIPIENTS_DESCRIPTION": "Utiliza los datos de un widget-Email como destinatario",
  "INTEGRATION_EXCEL_PROPERTY_COPY_TO_USER": "Copia al usuario",
  "INTEGRATION_EXCEL_PROPERTY_COPY_TO_USER_DESCRIPTION": "Envía una copia al usuario que rellena el formulario",
  "INTEGRATION_EXCEL_PROPERTY_IMAGE_QUALITY": "Calidad de la imagen",
  "INTEGRATION_EXCEL_PROPERTY_IMAGE_QUALITY_DESCRIPTION": "Más calidad llevará más tiempo para procesar el email",
  "INTEGRATION_EXCEL_PROPERTY_SUBJECT": "Asunto",
  "INTEGRATION_EXCEL_PROPERTY_SUBJECT_DESCRIPTION": "Asunto con placeholders opcionales",
  "INTEGRATION_EXCEL_PROPERTY_BODY": "Cuerpo del email",
  "INTEGRATION_EXCEL_PROPERTY_BODY_DESCRIPTION": "Cuerpo del email con placeholders opcionales",
  "DISABLED_DURING_TRIAL": "Esta función está desactivada durante el periodo prueba.",
  "LOGIN_FAILED_TIME_RELATED_TITLE": "Comprueba los ajustes de la hora",
  "LOGIN_FAILED_TIME_RELATED_SUBTITLE": "No puedes iniciar sesión, a causa de la hora del dispositivo.",
  "LOGIN_FAILED_TIME_RELATED_DESCRIPTION": "<b>SOLUCIÓN</b>: cambia la hora y la zona horaria de tu dispositivo para que refleje con exactitud la hora y la zona horaria de tu ubicación actual. A continuación, inténtalo de nuevo.",
  "LOGIN_FAILED_TIME_RELATED_LINK": "Reintentar el inicio de sesión",
  "TOUR_PROGRESS_STEP1_LABEL": "Cómo funciona",
  "TOUR_PROGRESS_STEP2_LABEL": "Cuéntanos más",
  "TOUR_PROGRESS_STEP3_LABEL": "Primer formulario",
  "TOUR_SKIP": "Saltar tour",
  "TOUR_STEP1_TRIAL": "Estás en tu prueba gratuita de 14 días",
  "TOUR_STEP1_BUILD_FORMS_WITH_COMPUTER": "Crea y modifica formularios desde tu escritorio",
  "TOUR_STEP1_FILL_FORMS_WITH_DEVICES": "Rellena formularios en tu teléfono o tablet",
  "TOUR_STEP1_RECEIVE_DATA": "Recibe un informe en tu bandeja de email",
  "TOUR_STEP2_HEADER": "Cuéntanos más",
  "TOUR_STEP3_HEADER": "¿Como podemos contactarlo?",
  "TOUR_ERROR_MODAL_TITLE": "Oops...",
  "TOUR_ERROR_MODAL_MESSAGE": "Algo ha ido mal en este tour. Mientras tanto puedes explorar {{appName}} tú mismo.",
  "TOUR_ERROR_MODAL_BUTTON": "Explorar {{appName}}",
  "FIRST_NAME": "Nombre",
  "LAST_NAME": "Apellido",
  "PAGE_CONTROL_PAGE_SIZE": "Tamaño de la página",
  "PAGE_CONTROL_TOTAL_ITEMS": "Total de ítems",
  "VIEW_MANAGEMENT_OVERVIEW": "Vista General",
  "VIEW_MANAGEMENT_REGISTRATIONS": "Registros",
  "VIEW_MANAGEMENT_TASKS": "Tareas",
  "VIEW_MANAGEMENT_OVERVIEW_COSTS": "Costes",
  "VIEW_MANAGEMENT_OVERVIEW_STATUS": "Estado",
  "VIEW_MANAGEMENT_OVERVIEW_PUBLISHED_ON": "El",
  "VIEW_MANAGEMENT_OVERVIEW_EDIT_FORM": "Editar",
  "VIEW_MANAGEMENT_OVERVIEW_EDIT_FORM_BETA": "Editar (Beta)",
  "VIEW_MANAGEMENT_OVERVIEW_SHARING": "Compartir",
  "FORM_REMOVE_MODAL_TITLE": "Eliminar Formulario",
  "FORM_REMOVE_MODAL_MESSAGE": "¿Estás seguro de que quieres eliminar este formulario?",
  "REMOVE_DRAFT_TITLE": "Eliminar Borrador",
  "REMOVE_DRAFT_MESSAGE": "¿Estás seguro de que quieres eliminar este borrador?",
  "TEMPLATE_REMOVE_MODAL_TITLE": "Eliminar Plantilla",
  "TEMPLATE_REMOVE_MODAL_MESSAGE": "¿Estás seguro de que quieres eliminar esta plantilla?",
  "UNPUBLISHED": "No Publicado",
  "PUBLISHED": "Publicado",
  "PUBLISHED_UPDATED": "Publicado, actualización disponible",
  "VIEW_MANAGEMENT_OVERVIEW_USER_MANAGEMENT": "Administrar usuarios",
  "VIEW_MANAGEMENT_OVERVIEW_PLACE_ON_MARKETPLACE": "Poner en el Marketplace",
  "VIEW_MANAGEMENT_OVERVIEW_PLACED_ON_MARKETPLACE": "Compartido en el Marketplace",
  "VIEW_MANAGEMENT_OVERVIEW_PLACED_ON_MARKETPLACE_HIDDEN": "Oculto del Marketplace",
  "VIEW_MANAGEMENT_OVERVIEW_VIEW_IN_MARKETPLACE": "Ver en el Marketplace",
  "VIEW_MANAGEMENT_OVERVIEW_EDIT_FORM_TEMPLATE_BUTTON": "Editar",
  "VIEW_MANAGEMENT_OVERVIEW_UPDATE_FORM_TEMPLATE_BUTTON": "Actualizar a la última versión",
  "VIEW_MANAGEMENT_OVERVIEW_UPDATE_FORM_TEMPLATE_ERROR_MESSAGE": "Tu Formulario Plantilla no pudo ser actualizado a la última versión. Por favor, inténtalo de nuevo después.",
  "VIEW_MANAGEMENT_OVERVIEW_HIDE_FORM_TEMPLATE_BUTTON": "Ocultar del Marketplace",
  "VIEW_MANAGEMENT_OVERVIEW_SHOW_FORM_TEMPLATE_BUTTON": "Mostrar en el Marketplace",
  "PLACE_ON_MARKETPLACE_PANEL_HEADER": "Poner Formulario en el Marketplace",
  "PLACE_ON_MARKETPLACE_PANEL_QUESTION": "Entonces, ¿quieres compartir tu Formulario con todos los otros usuarios?",
  "PLACE_ON_MARKETPLACE_PANEL_EXPLANATION": "Por favor, rellena primero este formulario. Esta info (excepto la de tu Cuenta Bancaria) será visible en el Marketplace.",
  "PLACE_ON_MARKETPLACE_EDIT_DEVELOPER_BUTTON": "Actualizar info",
  "FORM_TEMPLATE_NAMESPACE_LABEL": "Nombreespacio",
  "FORM_TEMPLATE_NAMESPACE_PLACEHOLDER": "Ejemplo: com.miempresa",
  "FORM_TEMPLATE_KEY_LABEL": "Clave",
  "FORM_TEMPLATE_KEY_PLACEHOLDER": "Ejemplo: formularioespecial",
  "FORM_TEMPLATE_TYPE_LABEL": "Tipo",
  "FORM_TEMPLATE_NAME_LABEL": "Nombre",
  "FORM_TEMPLATE_NAME_PLACEHOLDER": "Pon nombre a tu Formulario Plantilla",
  "FORM_TEMPLATE_DESCRIPTION_LABEL": "Descripción",
  "FORM_TEMPLATE_DESCRIPTION_PLACEHOLDER": "Describe tu Formulario Plantilla",
  "FORM_TEMPLATE_LOGO_LABEL": "Logo",
  "FORM_TEMPLATE_LOGO_PLACEHOLDER": "El URL del logo de tu Formulario Plantilla",
  "DIRECT_FORM_LINK": "Generar URL directa",
  "DIRECT_FORM_LINK_GENERATED": "Abrir URL directa",
  "DIRECT_FORM_LINK_REVOKE_MODAL_TITLE": "Revocar URL directa",
  "DIRECT_FORM_LINK_REVOKE_MODAL_MESSAGE": "¿Estás seguro de que quieres revocar la URL que da acceso directo a tu formulario? Esto significa que la URL dejará de funcionar.",
  "PUBLISH": "Publicar",
  "PUBLISH_FORM_VERSION": "Publicar esta versión",
  "PUBLISH_FORM_VERSION_MESSAGE": "¿Seguro que deseas publicar esta versión del formulario?",
  "PUBLISHED_BY": "Publicado por",
  "PUBLISHED_ON": "Publicado",
  "REMARKS": "Observaciones",
  "DRAFT": "Borrador",
  "FORM_HISTORY": "Historial de versiones",
  "FORM_DESCRIPTION_PLACEHOLDER": "Añade una breve descripción de tu formulario",
  "FORM_MOVE_MODAL_TITLE": "Mover",
  "FORM_SAVE_AS_TEMPLATE": "Guardar como plantilla",
  "FORM_SAVE_AS_TEMPLATE_MESSAGE": "¿Seguro que quieres guardar este formulario como plantilla?",
  "MOVE_FORM_MODAL_DESTINATION_FOLDER_LABEL": "Mover el formulario a la carpeta seleccionada",
  "MOVE_FORM_MODAL_DESTINATION_FOLDER_PLACEHOLDER": "Seleccionar carpeta",
  "FORM_VERSION_DRAFT_ALREADY_AVAILABLE": "Parece que tú u otro usuario está editando este formulario en otro lugar. Asegúrate de ser el único que está editando este formulario y vuelve a intentarlo.",
  "INTEGRATIONS_EOL_MESSAGE": "Este formulario utiliza una integración que dejó de funcionar. Por favor, elimínala o sustitúyela por otra integración o webhook. <a href=\"https://help.moreapp.com/es/support/tickets/new\">Contacta con soporte</a> si necesitas ayuda.",
  "DATASOURCE_PICKER_ADD": "Añadir una base de datos",
  "SEARCH_DATASOURCE_PLACEHOLDER": "Buscar bases de datos",
  "GROUP_PICKER_ADD_USER": "Invitar usuario(s)",
  "GROUP_PICKER_ADD_GROUP": "+ Crear un grupo nuevo",
  "GROUP_PICKER_ALL_USERS": "Todos los Usuarios",
  "GROUP_PICKER_INVITED": "(Invitado)",
  "MANAGE_ROLES": "Gestionar roles",
  "ROLE": "Rol",
  "SEARCH_GROUP_PLACEHOLDER": "Buscar grupos",
  "BILLING_TITLE": "Facturación",
  "BILLING_OVERVIEW_MENU_ITEM": "Vista General",
  "BILLING_INVOICES_MENU_ITEM": "Facturas",
  "BILLING_USAGE_MENU_ITEM": "Uso",
  "SUBTOTAL": "Subtotal",
  "TOTAL": "Total",
  "NEXT_INVOICE": "Próxima Factura",
  "PREVIEW_CHANGE_EXPLANATION": "Esta tabla muestra tu próxima factura cuando se aplican tus cambios. Consulta este artículo del <a href=\"https://help.moreapp.com/support/solutions/folders/13000014069\" target=\"_blank\">Centro de Ayuda</a> que explica cómo se crea tu factura",
  "PREVIEW_CHANGE": "Vista previa de cambios",
  "CHANGE_SUBSCRIPTION_BUTTON": "Cambiar suscripción",
  "SCHEDULED_SUBSCRIPTION_CHANGES_BUTTON": "Ver cambio",
  "SCHEDULED_SUBSCRIPTION_CHANGES": "Cambio de suscripción programado",
  "SCHEDULED_SUBSCRIPTION_CHANGES_ON": "Programado",
  "SCHEDULED_CANCEL_MESSAGE": "Tu suscripción está programada para cancelarse al final del periodo de facturación actual",
  "CANCEL_SUBSCRIPTION_SCHEDULE_BUTTON": "Cancelar cambio",
  "SELECT_PLAN": "Elige tu plan",
  "YOUR_SELECTED_PLAN": "Plan elegido",
  "LOADING_PLANS": "Cargando planes disponibles",
  "MONTHLY_PLAN": "Plan Mensual",
  "YEARLY_PLAN": "Plan Anual",
  "MONTH_SHORT": "mes",
  "YEAR_SHORT": "año",
  "TRIAL_ACTIVE": "Prueba activa",
  "DAYS": "días",
  "TRIAL_DAYS_REMAINING": "días restantes",
  "TRIAL_ENDS_TODAY": "Tu periodo de prueba acaba hoy",
  "TRIAL_ENDS_TOMORROW": "Tu periodo de prueba acaba mañana",
  "TRIAL_ENDS_ON": "Tu periodo de prueba acaba el",
  "SUBMISSIONS": "Envíos",
  "USERS": "Usuarios",
  "USAGE": "Uso",
  "UNLIMITED": "Ilimitado",
  "CHANGE_PLAN": "Cambiar Plan",
  "CANCEL_PLAN": "Cancelar Plan",
  "UNCANCEL_PLAN": "Anular Cancelación",
  "PLAN_MORE_OPTIONS": "Más opciones",
  "DELETE_ACCOUNT": "Eliminar Cuenta",
  "TERMINATION_OPEN_INVOICE_MODAL_TITLE": "Aún tienes una factura pendiente",
  "TERMINATION_OPEN_INVOICE_MODAL_MESSAGE": "Aún tienes una factura pendiente. Por lo tanto, no puedes realizar esta acción. Ve a la pestaña Facturas para completar el pago.",
  "TERMINATION_OPEN_INVOICE_MODAL_GOTO_INVOICES_ACTION": "Ir a Facturas",
  "CANCEL_PLAN_MODAL_TITLE": "Cancelar Plan",
  "CANCEL_PLAN_MODAL_MESSAGE": "Puedes seguir utilizando nuestro producto hasta el final de tu ciclo de facturación. Después, no se procesarán los envíos. Podrás seguir accediendo a tu cuenta para ver y exportar tus datos durante 30 días. Una vez transcurrido ese plazo, eliminaremos tu cuenta.",
  "UNCANCEL_PLAN_MODAL_TITLE": "Anular Cancelación",
  "UNCANCEL_PLAN_MODAL_MESSAGE": "Genial, ¿has cambiado de opinión y quieres seguir utilizando MoreApp?",
  "CANCEL_PLAN_ALREADY_SCHEDULED_MODAL_TITLE": "Plan programado para cancelar",
  "CANCEL_PLAN_ALREADY_SCHEDULED_MODAL_MESSAGE": "Tu plan está programado para ser cancelado al final de tu ciclo de facturación.",
  "DOWNGRADE_FEEDBACK_TITLE": "Feedback",
  "DOWNGRADE_DESCRIPTION": "Por favor, haznos saber cómo podemos mejorar nuestro producto. Valoramos mucho tus comentarios.</p>",
  "TERMINATION_FEEDBACK_MODAL_TITLE": "Feedback",
  "TERMINATION_FEEDBACK_MODAL_DESCRIPTION": "<h4>Tu opinión nos importa</h4><p>Por favor, dinos cómo podemos mejorar nuestro producto.</p>",
  "TERMINATION_FEEDBACK_MODAL_REASON_LABEL_CANCEL_PLAN": "¿Por qué quieres cancelar tu plan?",
  "TERMINATION_FEEDBACK_MODAL_REASON_LABEL_DELETE_ACCOUNT": "¿Por qué quieres eliminar tu cuenta?",
  "TERMINATION_FEEDBACK_MODAL_REASON_FOUND_ALTERNATIVE_LABEL": "He encontrado otra alternativa",
  "TERMINATION_FEEDBACK_MODAL_REASON_TOO_EXPENSIVE_LABEL": "Es demasiado caro",
  "TERMINATION_FEEDBACK_MODAL_REASON_NO_LONGER_NEEDED_LABEL": "Ya no es necesario",
  "TERMINATION_FEEDBACK_MODAL_REASON_BANKRUPT_LABEL": "Voy a cerrar mi negocio",
  "TERMINATION_FEEDBACK_MODAL_REASON_UNHAPPY_LABEL": "El producto no satisface la necesidad de mi empresa",
  "TERMINATION_FEEDBACK_MODAL_REASON_MISSING_FEATURE_LABEL": "Falta alguna característica",
  "TERMINATION_FEEDBACK_MODAL_REASON_OTHER_LABEL": "Otros",
  "TERMINATION_FEEDBACK_MODAL_REASON_ERROR": "Por favor, selecciona al menos un motivo.",
  "TERMINATION_FEEDBACK_MODAL_GOODBYE_MESSAGE_LABEL": "¿Hay algo más que quieras compartir?",
  "TERMINATION_FEEDBACK_MODAL_GOODBYE_MESSAGE_ERROR": "Por favor, escribe un mensaje.",
  "TERMINATION_FEEDBACK_MODAL_SUBMIT_ACTION_CANCEL_PLAN": "Cancelar Plan",
  "TERMINATION_FEEDBACK_MODAL_SUBMIT_ACTION_DELETE_ACCOUNT": "Eliminar Cuenta",
  "DELETE_ACCOUNT_CONFIRMATION_MODAL_TITLE": "Sí, quiero eliminar mi cuenta",
  "DELETE_ACCOUNT_CONFIRMATION_MODAL_MESSAGE": "Entiendo que, transcurridos <b>30 días</b>, mi cuenta de empresa <b>{{customerName}}</b> ({{customerId}}) y todos los datos relacionados se eliminarán de forma permanente.",
  "DELETE_ACCOUNT_CONFIRMATION_MODAL_CONFIRM_LABEL": "Por favor, confírmalo introduciendo el nombre de tu cuenta",
  "DELETE_ACCOUNT_CONFIRMATION_MODAL_CONFIRM_ERROR": "El nombre de cuenta introducido no coincide con el nombre de la cuenta que estás intentando eliminar.",
  "DELETE_ACCOUNT_CONFIRMATION_MODAL_SUBMIT_ACTION": "Eliminar Cuenta",
  "DELETE_ACCOUNT_CONFIRMATION_MODAL_SUBMIT_ERROR": "No se ha podido eliminar la cuenta. Vuelve a intentarlo más tarde.",
  "BILLING_PROFILE": "Perfil de Facturación",
  "BILLING_PHONE_NUMBER": "Número de teléfono",
  "INVOICE_ADDRESS": "Dirección de Facturación",
  "PAYMENT_METHOD": "Método de Pago",
  "UPCOMING_INVOICE": "Próxima Factura",
  "UPCOMING_INVOICE_TRIAL_NO_PAYMENT_TITLE": "En periodo de prueba",
  "UPCOMING_INVOICE_TRIAL_NO_PAYMENT_CONTENT": "Empieza gratis con MoreApp durante el periodo de prueba. ¿Deseas continuar después de la prueba? Añade un método de pago para recibir tu primera factura.",
  "UPCOMING_INVOICE_TRIAL_PAYMENT_CONTENT": "Empieza gratis con MoreApp durante el periodo de prueba. Una vez finalizado, recibirás la siguiente factura.",
  "UPCOMING_INVOICE_TRIAL_PAYMENT_AMOUNT_DUE": "Cantidad adeudada después de la prueba",
  "UPCOMING_INVOICE_SCHEDULED_DELETE_TITLE": "No hay próximas facturas",
  "UPCOMING_INVOICE_SCHEDULED_DELETE_CONTENT": "Tu suscripción ha sido cancelada. No hay próximas facturas. Tu periodo de facturación finaliza el día ",
  "CARD_HOLDER_NAME": "Nombre Titular de la Tarjeta",
  "CARD_HOLDER_NAME_PLACEHOLDER": "ej. Javier Sánchez",
  "ADD_CARD": "+ Añadir Método de Pago",
  "MAKE_DEFAULT": "Estabelecer como predeterminada",
  "EXPIRES": "caduca",
  "PAYMENT_METHOD_REMOVE_TITLE": "Eliminar método de pago",
  "PAYMENT_METHOD_REMOVE_MESSAGE": "¿Seguro que deseas eliminar este método de pago?",
  "PLAN_LEAF": "Hoja",
  "PLAN_BRANCH": "Rama",
  "PLAN_TREE": "Árbol",
  "PLAN_FOREST": "Bosque",
  "PLAN_LEAF_DESCRIPTION": "Crea formularios profesionales",
  "PLAN_BRANCH_DESCRIPTION": "Procesamiento de datos avanzado",
  "PLAN_TREE_DESCRIPTION": "Para todo el negocio",
  "PLAN_FOREST_DESCRIPTION": "Para empresas",
  "PLAN_LEAF_FEATURES_HEADER": "Características",
  "PLAN_BRANCH_FEATURES_HEADER": "Plan Hoja +",
  "PLAN_TREE_FEATURES_HEADER": "Plan Rama +",
  "PLAN_FOREST_FEATURES_HEADER": "Plan Árbol +",
  "PLAN_AMOUNT_OF_USERS_ONE": "Un usuario",
  "PLAN_AMOUNT_OF_USERS_UNLIMITED": "Usuarios ilimitados",
  "PLAN_AMOUNT_OF_SUBMISSIONS": "{{amount}} envíos gratis",
  "PLAN_AMOUNT_OF_TREES": "{{amount}} árboles",
  "PLAN_LEAF_PRICE_PER_EXTRA_SUBMISSION": "0,40€ / envío adicional",
  "PLAN_BRANCH_PRICE_PER_EXTRA_SUBMISSION": "0,40€ / envío adicional",
  "PLAN_TREE_PRICE_PER_EXTRA_SUBMISSION": "0,40€ / envío adicional",
  "PLAN_FOREST_PRICE_PER_EXTRA_SUBMISSION": "0,10€ / envío adicional",
  "FEATURE_FORM_BUILDER": "Generador de Formularios",
  "FEATURE_APP": "App",
  "FEATURE_EXCEL_EXPORT": "Exportación a Excel",
  "FEATURE_EMAIL": "Email con PDF",
  "FEATURE_RULES": "Reglas",
  "FEATURE_API": "Acceso a la API",
  "FEATURE_MARKETPLACE_TEMPLATES": "Plantillas",
  "FEATURE_TASKS": "Tareas",
  "FEATURE_LOGO_PDF": "Tu logo en el PDF",
  "FEATURE_DATA_IMPORT": "Importación de datos",
  "FEATURE_MULTIPLE_PDF": "Múltiples emails",
  "FEATURE_ADVANCED_PDF": "PDF personalizado",
  "FEATURE_DATA_IMPORT_AUTOMATIC": "Importación de datos automática",
  "FEATURE_SINGLE_SIGN_ON": "Inicio de Sesión Único",
  "FEATURE_BRANDING": "Branding",
  "FEATURE_SSO": "Inicio de Sesión Único",
  "FEATURE_SENDING_DOMAIN": "Dominio de email personalizado",
  "FEATURE_DEDICATED_SUPPORT": "Soporte dedicado",
  "VAT": "IVA",
  "APPLIED_BALANCE": "Saldo aplicado",
  "AMOUNT_DUE": "Cantidad adeudada",
  "REMAINING_BALANCE": "Saldo restante",
  "CREDIT_CARD": "Tarjeta de Crédito",
  "SEPA_DIRECT_DEBIT": "Débito Directo SEPA",
  "PAY_ON_FILE": "Transferencia",
  "NOT_ALLOWED_TO_PAY_ON_FILE": "Esta opción no está disponible en tu suscripción actual",
  "SEPA_MANDATE": "Al proporcionar tu IBAN, autorizas (A) a MoreApp y a Stripe, nuestro proveedor de servicios de pago, a enviar instrucciones a tu banco para que cargue tu cuenta y (B) a tu banco para que cargue tu cuenta de acuerdo con esas instrucciones. Este mandato está destinado únicamente a las transacciones entre empresas. No tendrás derecho a que tu banco te devuelva el dinero después de que se haya efectuado el cargo en tu cuenta, pero sí tienes derecho a solicitar a tu banco que no cargue el importe en tu cuenta hasta el día en que deba efectuarse el pago.",
  "INVOICE_NUMBER": "Número de factura",
  "INVOICE_DATE": "Fecha",
  "INVOICE_TOTAL": "Total",
  "INVOICE_PDF": "PDF",
  "INVOICE_STATUS": "Estado",
  "BASE_MONTH": "Plan mensual",
  "BASE_YEAR": "Plan anual",
  "FORMS_MONTH": "Uso mensual",
  "FORMS_YEAR": "Uso anual",
  "BRANDING_MONTH": "Branding mensual",
  "BRANDING_YEAR": "Branding anual",
  "ADDON_MONTH": "Extensión mensual",
  "ADDON_YEAR": "Extensión anual",
  "MAINTENANCE_FTPS_MONTH": "Mantenimiento - FTPS mensual",
  "MAINTENANCE_FTPS_YEAR": "Mantenimiento - FTPS anual",
  "MANUAL_INVOICE_DESCRIPTION": "Las facturas se enviarán a tu email de facturación",
  "USAGE_DAILY": "Diariamente",
  "USAGE_MONTHLY": "Mensualmente",
  "MONTH": "Mes",
  "YEAR": "Año",
  "INVOICE_REFERENCE": "Información adicional de la factura",
  "INVOICE_REFERENCE_DESCRIPTION": "Esta información se añadirá a la factura para tu administración, por ejemplo un número de orden de compra.",
  "SUBSCRIPTION_CHANGE_IMMEDIATE": "Este cambio se efectuará inmediatamente",
  "SUBSCRIPTION_CHANGE_SCHEDULED": "Este cambio se efectuará en tu próxima factura",
  "TREES_PLANTED": "Árboles Plantados",
  "TREES_PLANTED_TOTAL": "árboles plantados en total",
  "TREES_PLANTED_PER_MONTH": "árboles plantados cada mes",
  "TREES_PLANTED_PER_YEAR": "árboles plantados cada año",
  "DOWNGRADE_QUESTION": "¿Por qué quieres cambiar de plan?",
  "DOWNGRADE_NOT_NEEDED": "Ya no lo necesito",
  "DOWNGRADE_NOT_NEEDED_DESCRIPTION": "Observaciones",
  "DOWNGRADE_ALTERNATIVE": "He encontrado una alternativa",
  "DOWNGRADE_ALTERNATIVE_DESCRIPTION": "¿Qué alternativa?",
  "DOWNGRADE_MISSING_FEATURE": "Falta una característica",
  "DOWNGRADE_MISSING_FEATURE_DESCRIPTION": "¿Qué característica te falta?",
  "DOWNGRADE_PRICING": "Es demasiado caro",
  "DOWNGRADE_PRICING_DESCRIPTION": "Observaciones",
  "DOWNGRADE_UNSATISFIED": "Insatisfecho/a",
  "DOWNGRADE_UNSATISFIED_DESCRIPTION": "¿Qué podemos mejorar?",
  "DOWNGRADE_OTHER": "Otros",
  "DOWNGRADE_OTHER_DESCRIPTION": "¿Cuál es el motivo?",
  "SELECT_OPTION": "Selecciona una opción",
  "CONTACT_BUTTON": "Contáctanos",
  "CONTACT_LINK": "https://meet.moreapp.com/meetings/moreapp/account-manager-es",
  "PRICE_LABEL_NOW": "ahora",
  "PRICE_LABEL_UPCOMING": "desde enero 2024",
  "PRICE_ANNUAL_BILLED_MONTHLY": "Facturación anual",
  "BILLING_FLOW_STEP1_LABEL": "Seleccionar un Plan",
  "BILLING_FLOW_STEP2_LABEL": "Detalles de Facturación",
  "BILLING_FLOW_STEP3_LABEL": "Método de Pago",
  "BILLING_FLOW_STEP4_LABEL": "Confirmar",
  "ONETIME": "Una vez",
  "MONTHLY": "Mensual",
  "YEARLY": "Anual",
  "RECURRENCE": "Recurrencia",
  "BRANDING": "Branding",
  "RESTORE": "Recuperar",
  "RESTORE_FORM_MESSAGE": "Al recuperar este formulario, volverá a aparecer en la lista de formularios de la Plataforma. También se mostrará en la aplicación, si el formulario estaba publicado.",
  "SIGN_UP_CUSTOMER_ACCOUNT_TITLE": "Crea tu cuenta gratis",
  "SIGN_UP_CUSTOMER_ACCOUNT_SUB_TITLE": "Empieza tu prueba gratuita de 14 días. No se requiere tarjeta de crédito.",
  "SIGN_UP_CUSTOMER_EMAIL_ADDRESS": "Dirección de correo electrónico",
  "SIGN_UP_CUSTOMER_PASSWORD": "Contraseña",
  "SIGN_UP_CUSTOMER_BUTTON": "Crear cuenta",
  "EMAIL_ADDRESS_PLACEHOLDER": "email@empresa.com",
  "EMAIL_VALIDATION_VALIDATING": "Validando email...",
  "EMAIL_VALIDATION_FAILED": "Fallo al validar la dirección de correo electrónico. No hemos podido encontrar la cuenta correspondiente a la validación de tu cuenta. Tu cuenta puede que ya esté validada.",
  "EMAIL_VALIDATION_SUCCESS": "¡Tu dirección email se ha verificado con éxito!",
  "WIDGET_PIN_CLICK_IMAGE_TO_ADD": "Haz click en la imagen para añadir pins",
  "ALERT_TOUCH_DEVICE": "La Plataforma no funciona correctamente en dispositivos móviles. Por favor, utiliza tu portátil o PC",
  "ALERT_PAYMENT_METHOD_EXPIRED": "<b>¡Tienes un método de pago caducado!</b> Asegúrate de <a href ui-sref='portal.customers.billing.overview'>añadir un nuevo método de pago</a> para seguir enviando formularios.",
  "ADD_VIEW_TEMPLATE_TYPE": "Utilizando plantilla:",
  "ADD_VIEW_TEMPLATE_TYPE_NONE": "Blanco",
  "ADD_VIEW_TEMPLATE_TYPE_FORM_TEMPLATE": "Instalado",
  "ADD_VIEW_TEMPLATE_TYPE_GET_MORE_TEMPLATES": "Obtener más formularios del Marketplace",
  "BLANK_FORM": "Blanco",
  "BLANK_FORM_DESCRIPTION": "Empezar con un formulario vacío",
  "AGREEMENTS_MODAL_TITLE": "Actualizaciones de Términos y Condiciones",
  "AGREEMENTS_MODAL_MESSAGE": "Hemos actualizado nuestro Acuerdo de usuario final, incluido su Anexo de Procesamiento de Datos. Por favor léelo cuidadosamente. Si tienes alguna pregunta o inquietud, contáctanos. De lo contrario, no se requiere ninguna acción y la actualización de los términos y condicione será efectuada el 1 de febrero de 2019.",
  "AGREEMENTS_MODAL_ACCEPT_BUTTON": "Continuar",
  "ADD_PHOTO": "Añadir foto",
  "CAPTURE_PHOTO": "Capturar foto",
  "BROWSE_PHOTO": "Buscar fotos",
  "DRAW_SIGNATURE": "Dibuje la firma",
  "START_DRAWING": "Empezar dibujo",
  "VIEW_EDITOR_CUSTOMER_RESOURCE_LOOKUP_BUTTON_BACKGROUND_IMAGE": "Escoge imagen de fondo",
  "ADD_VIDEO": "Añadir video",
  "SIGN_UP_THANKS": "¡Gracias por registrarte!",
  "SIGN_UP_CHECK_MAIL": "Por favor, revise su bandeja de entrada para verificar su dirección de correo electrónico.",
  "FORM_BUILDER": "Generador de Formularios",
  "MOBILE_APP": "App",
  "PDF_REPORTS": "Informe PDF",
  "EXCEL_EXPORT": "Exportación a Excel",
  "TASKS": "Tareas",
  "PDF_LOGO": "Tu logo en el PDF",
  "REALTIME_DATA_SOURCES": "Importación de datos automática",
  "PDF_LAYOUT": "PDF personalizado",
  "RULES": "Reglas",
  "API_ACCESS": "Acceso a la API",
  "COLOR_THEMES": "Temas de color",
  "THEMES_SYSTEM": "Temas predeterminados",
  "THEMES_CUSTOM": "Temas personalizados",
  "GROUP_ROLES": "Administración avanzada de usuarios",
  "CUSTOM_DOMAIN": "Dominio Personalizado",
  "DEDICATED_ACCOUNT_MANAGER": "Soporte dedicado",
  "PREMIUM_SUPPORT": "Soporte Premium",
  "FILE_SELECT": "Selecciona un archivo",
  "NEW_CUSTOMER": "+ Nuevo Cliente",
  "TO_PORTAL": "Ir al Portal",
  "EXPIRED": "Caducada",
  "READ_MORE": "Leer más",
  "CHOOSE_A_PLAN": "Elige un plan",
  "TRIAL": "Prueba",
  "TRIAL_BANNER_TITLE": "Estás en una prueba gratuita",
  "TRIAL_BANNER_DAYS_LEFT": "{{daysLeft}} días restantes",
  "TRIAL_BANNER_SUBMISSIONS_LEFT": "{{submissionsLeft}} registros restantes",
  "UPGRADE_NOW": "Actualiza ahora",
  "CONTACT_SALES": "Contáctanos",
  "BANNER_DELETION_LABEL": "Tu cuenta está programada para ser eliminada en {{days}} día(s)",
  "BANNER_DELETE_TODAY_LABEL": "Tu cuenta será eliminada hoy",
  "BANNER_DELETION_MAIL_ACCOUNT_OWNER_SUBJECT": "Elige un plan para seguir utilizando MoreApp",
  "BANNER_DELETION_MAIL_ACCOUNT_OWNER_BODY": "Por favor, selecciona un plan para seguir utilizando MoreApp. De lo contrario, esta cuenta se eliminará en {{days}} día(s).",
  "BANNER_NO_SUBSCRIPTION_TITLE": "Ninguna suscripción activa",
  "BANNER_NO_SUBSCRIPTION_DESCRIPTION": "Por favor, selecciona un plan para continuar",
  "BANNER_MAINTENANCE_ANNOUNCEMENT": "<b>Importante:</b> Debido a trabajos de <a href='https://help.moreapp.com/support/solutions/articles/13000100648' target='_blank'>mantenimiento</a> programados, MoreApp no estará disponible el miércoles 19/04/2023 entre las 19:00-21:00 CET",
  "BANNER_MAINTENANCE_ANNOUNCEMENT_BRANDED": "<b>Importante:</b> Debido a trabajos de mantenimiento programados, nuestro software no estará disponible el miércoles 19/04/2023 entre las 19:00-21:00 CET.",
  "BANNER_OVER_USAGE_TITLE": "Has superado el número de envíos gratuitos.",
  "BANNER_OVER_USAGE_DESCRIPTION": "<b>Has superado el número de envíos gratuitos.</b> ✈️ {{ nrOfSubmissionsOverIncluded }} envíos adicionales 💰 {{ (extraUsageCostsInCents / 100 | currencySymbol:'eur') }} coste adicional",
  "BANNER_OVER_USAGE_CTA_UPGRADE": "Actualiza ahora",
  "BANNER_OVER_USAGE_CTA_CONTACT": "Contáctanos",
  "BANNER_CREDIT_CARD_EXPIRED_TITLE": "Tu tarjeta de crédito ha caducado.",
  "BANNER_CREDIT_CARD_EXPIRED_DESCRIPTION": "Por favor, añade un método de pago válido.",
  "BANNER_CREDIT_CARD_EXPIRED_PRIMARY_ACTION": "Actualizar Método de Pago",
  "BANNER_PAST_DUE_INVOICE_TITLE": "Tienes una factura pendiente de pago.",
  "BANNER_PAST_DUE_INVOICE_DESCRIPTION": "Por favor, paga la factura antes de que se bloquee tu cuenta.",
  "BANNER_PAST_DUE_INVOICE_PRIMARY_ACTION": "Pagar Factura",
  "BANNER_UNPAID_INVOICE_TITLE": "Tu cuenta ha sido bloqueada debido a facturas sin pagar.",
  "BANNER_UNPAID_INVOICE_DESCRIPTION": "Paga tu factura para desbloquear la cuenta.",
  "BANNER_UNPAID_INVOICE_PRIMARY_ACTION": "Pagar Factura",
  "DATA_SOURCE_MANAGEMENT_ENTRIES_SELECT_COLUMNS": "Vista de selección de columnas",
  "DATA_SOURCE_MANAGEMENT_EDIT": "Editar Base de Datos",
  "DATA_SOURCE_MANAGEMENT_UPLOAD_EXCEL": "Subir Excel",
  "DATA_SOURCE_MANAGEMENT_SCHEDULE_URL_IMPORT": "Programar importación",
  "DATA_SOURCE_MANAGEMENT_SEARCH": "Buscar",
  "DATA_SOURCE_MANAGEMENT_ENABLE": "Habilitar",
  "DATA_SOURCES_TITLE": "Base de Datos",
  "DATA_SOURCES_LAST_UPDATED_LABEL": "Última actualización",
  "DATA_SOURCES_GENERAL_INFORMATION": "Información General",
  "DATA_SOURCES_LAST_SUCCESSFULL_UPDATE_LABEL": "Última actualización correcta",
  "DATA_SOURCE_MANAGEMENT_LAST_UPDATE_WARNINGS": "Mostrar avisos",
  "DATA_SOURCES_URL_CONFIGURATION_LABEL": "Configuración URL",
  "DATA_SOURCES_SCHEDULE_URL_IMPORT_BUTTON": "Programar importación URL",
  "DATA_SOURCES_SCHEDULE_URL_IMPORT_ACTIVE": "Importando...",
  "DATA_SOURCES_SCHEDULE_EXCEL_IMPORT_BUTTON": "Programar importación Excel",
  "DATA_SOURCES_SCHEDULE_EXCEL_IMPORT_ACTIVE": "Importando...",
  "DATA_SOURCES_WARNINGS_LAST_WARNINGS": "Últimas advertencias:",
  "DATA_SOURCES_WARNINGS_SHOW_ALL": "( Mostrar todo {{numberOfWarningMessages}} )",
  "DATA_SOURCES_WARNINGS_SHOW_LESS": "( Mostrar menos )",
  "DATA_SOURCE_UPLOAD_EXCEL_TITLE": "Cargar archivo Excel",
  "DATA_SOURCE_UPLOAD_EXCEL_FILE_TOO_LARGE_MESSAGE": "El archivo seleccionado es demasiado grande para subirlo. El límite de archivo para la carga de archivos de Excel es de 10MB.",
  "DATA_SOURCE_UPLOAD_EXCEL_MESSAGE": "Seleccionar un Excel para importar. Asegúrate de que la extensión del Excel sea `.xlsx`. Los documentos `.xls` no serán importados.",
  "DATA_SOURCE_UPLOAD_EXCEL_DROP_FILES_HERE": "deja los archivos aquí",
  "DATA_SOURCE_UPLOAD_EXCEL_DROP_FILES_NOT_SUPPORTED": "¡No se permite dejar archivos vía HTML5!",
  "DATA_SOURCE_MANAGEMENT_ENTRY_DETAIL_TITLE": "Detalles de la Entrada",
  "DATA_SOURCES_URL_CONFIGURATION_URL_LABEL": "URL",
  "DATA_SOURCES_URL_CONFIGURATION_PARAMETERS_LABEL": "Parámetros",
  "DATA_SOURCES_LAST_UPDATE_TYPE_LABEL": "Tipo de última actualización",
  "DATA_SOURCES_SEARCH_LABEL": "Base de Datos",
  "DATA_SOURCES_ADD_BUTTON": "+ Añadir base de datos",
  "ADD_DATA_SOURCE_PANEL_TITLE": "Añadir una base de datos",
  "EDIT_DATA_SOURCE_PANEL_TITLE": "Editar base de datos",
  "DATA_SOURCE_PANEL_NAME_LABEL": "Nombre",
  "DATA_SOURCE_PANEL_NAME_PLACEHOLDER": "Da un nombre a la base de datos",
  "DATA_SOURCE_PANEL_TYPE_LABEL": "Tipo",
  "DATA_SOURCE_PANEL_TYPE_EXCEL_LABEL": "Excel",
  "DATA_SOURCE_PANEL_TYPE_URL_LABEL": "URL",
  "DATA_SOURCE_PANEL_TYPE_GOOGLE_SPREADSHEET_LABEL": "Google Spreadsheet",
  "DATA_SOURCE_PANEL_URL_CONFIGURATION_URL_LABEL": "URL",
  "DATA_SOURCE_PANEL_URL_CONFIGURATION_URL_PLACEHOLDER": "Introduce una URL para recuperar la base de datos",
  "DATA_SOURCE_PANEL_URL_CONFIGURATION_PARAMETERS_LABEL": "Parámetros",
  "DATA_SOURCE_PANEL_URL_CONFIGURATION_ADD_PARAMETER": "Añadir Parámetros",
  "DATA_SOURCE_PANEL_URL_CONFIGURATION_NO_PARAMETERS": "No hay parámetros",
  "DATA_SOURCE_PANEL_URL_CONFIGURATION_PARAM_KEY": "Clave",
  "DATA_SOURCE_PANEL_URL_CONFIGURATION_PARAM_VALUE": "Valor",
  "DATA_SOURCE_PANEL_URL_CONFIGURATION_REQUEST_HEADERS_LABEL": "Encabezados de solicitud",
  "DATA_SOURCE_PANEL_URL_CONFIGURATION_REQUEST_HEADERS_ADD_BUTTON": "Añadir encabezados de solicitud",
  "DATA_SOURCE_PANEL_URL_CONFIGURATION_REQUEST_HEADERS_KEY": "Clave",
  "DATA_SOURCE_PANEL_URL_CONFIGURATION_REQUEST_HEADERS_VALUE": "Valor",
  "DATA_SOURCE_PANEL_URL_CONFIGURATION_INTERVAL_LABEL": "Intervalo de Actualización",
  "DATA_SOURCE_PANEL_URL_CONFIGURATION_BASIC_AUTH": "Autentificación Básica",
  "DATA_SOURCE_PANEL_GOOGLE_SPREADSHEETS_AUTHENTICATE": "Iniciar sesión con Google",
  "AUTHENTICATED_WITH_GOOGLE": "Autentificado con Google",
  "CHANGE_ACCOUNT": "cambiar cuenta",
  "DATA_SOURCE_PANEL_GOOGLE_SPREADSHEETS_ID_LABEL": "Google Spreadsheet ID",
  "DATA_SOURCE_PANEL_GOOGLE_SPREADSHEETS_ID_PLACEHOLDER": "Valor entre /d/ y /edit en la URL de tu spreadsheet",
  "DATA_SOURCE_GRID_NO_ENTRIES": "No hay entradas para esta base de datos.",
  "DATA_SOURCE_GRID_LOADING_MESSAGE": "Cargando entradas",
  "DATA_SOURCE_GRID_IMPORTING_ENTRIES": "Importando entradas",
  "DATA_SOURCE_MANAGEMENT_LAST_FAILED": "Última actualización fallida",
  "DATA_SOURCE_MANAGEMENT_NO_UPDATE": "Última actualización: nunca",
  "DATA_SOURCE_MANAGEMENT_LAST_UPDATE": "Última actualización",
  "DATA_SOURCE_WARNINGS_TITLE": "Avisos de importación",
  "DATA_SOURCE_REMOVE_TITLE": "Eliminar Base de Datos",
  "DATA_SOURCE_REMOVE_QUESTION": "¿Estás seguro de que quieres eliminar esta base de datos? ¡Esta acción no se puede deshacer!",
  "DATA_SOURCE_ENABLE_TITLE": "Habilitar Base de Datos",
  "DATA_SOURCE_ENABLE_MESSAGE": "La base de datos se ha inhabilado porque 10 o más importaciones consecutivas fallaron. Asegúrate de tener una configuración url correcta. Si habilitas la base de datos, lo intentaremos de nuevo.",
  "CLIENTS": "Apps",
  "CLIENTS_TITLE": "Apps",
  "SETTINGS_TITLE": "Ajustes",
  "SETTINGS_TAB_GENERAL": "General",
  "SETTINGS_TAB_SSO": "Inicio de Sesión Único",
  "SETTINGS_TAB_SENDING_DOMAIN": "Dominio de email",
  "BILLING_SUCCESS_MESSAGE": "Se ha actualizado la información de facturación del cliente con éxito.",
  "BILLING_DETAILS_HEADER": "Cliente",
  "BILLING_INVOICES_HEADER": "Cliente",
  "GENERAL_HEADER": "General",
  "GENERAL_SUCCESS_MESSAGE": "Se han actualizado los ajustes del cliente con éxito.",
  "LOGO_HEADER": "Logo",
  "LOGO_SELECT": "Seleccionar logo",
  "SETTINGS_DATE_FORMAT": "Formato de Fecha",
  "SETTINGS_CUSTOMER_NAME": "Nombre de la Empresa",
  "SETTINGS_TAB_THEMES": "Temas",
  "SETTINGS_TAB_THEMES_CUSTOM": "Temas Personalizados",
  "SETTINGS_TAB_THEMES_ADD_BUTTON": "Añadir Tema",
  "THEME_FORM": "Tema",
  "THEME_FORM_NAME_LABEL": "Nombre",
  "THEME_FORM_BUTTON_LABEL": "Tecla",
  "THEME_FORM_SAVE_BUTTON_LABEL": "Tecla Guardar",
  "THEME_FORM_BAR_LABEL": "Barra",
  "THEME_FORM_BACKGROUND_LABEL": "Fondo",
  "THEME_FORM_WIDGET_LABEL": "Widget",
  "SETTINGS_TAB_SERVICE_ACCOUNTS": "Service Accounts",
  "SETTINGS_TAB_WEBHOOKS": "Webhooks",
  "SETTINGS_TAB_APIKEYS": "API keys",
  "TOKEN": "Token",
  "API_KEY_NEW": "Nueva API key",
  "API_KEY_CREATED": "Nueva API key creada",
  "API_KEY_SAFETY": "Guárdala en un lugar seguro",
  "API_KEY_CREATED_MESSAGE": "Guarda esta clave en un lugar seguro, como un administrador de contraseñas. <br><b>No podrás volver a verla.</b",
  "API_KEY_REMOVE_TITLE": "¿Eliminar API key?",
  "API_KEY_REMOVE_MESSAGE": "Eliminar esta clave puede hacer que dejen de funcionar las integraciones que la usan. No se puede restaurar una vez eliminada.",
  "WEBHOOK_DETAILS": "Detalles del Webhook",
  "WEBHOOK_ATTEMPTS": "Intentos del Webhook",
  "WEBHOOK_ATTEMPTS_MESSAGE": "Intentos de enviar un evento a tu endpoint en los últimos 30 días",
  "WEBHOOK_REQUEST_BODY": "Request body",
  "WEBHOOK_REQUEST_BODY_MESSAGE": "El payload que enviamos a tu endpoint",
  "WEBHOOK_RESPONSE": "Respuesta",
  "WEBHOOK_RESPONSE_MESSAGE": "La respuesta que recibimos de tu endpoint",
  "WEBHOOK_REMOVE_TITLE": "¿Eliminar Webhook?",
  "WEBHOOK_REMOVE_MESSAGE": "¿Seguro que quieres eliminar este Webhook? ¿Sabías que también puedes <b>desactivarlo</b> accediendo a los detalles en la columna Vista?",
  "ADD_SUBSCRIBER": "Add subscriber",
  "EVENT_TYPES": "Event types",
  "SELECT_EVENT_TYPES": "Selecciona los tipos de evento a los que deseas suscribirte",
  "SECRET": "Secreto",
  "SERVICE_ACCOUNTS_NAME": "Nombre",
  "SERVICE_ACCOUNTS_TYPE": "Tipo",
  "SERVICE_ACCOUNTS_ACTIONS": "Acciones",
  "SERVICE_ACCOUNTS_RECONNECT": "Reconectar",
  "SERVICE_ACCOUNTS_DELETE": "Eliminar",
  "SERVICE_ACCOUNTS_ADD": "Añadir Service Account",
  "SERVICE_ACCOUNTS_NON_FOUND": "No hay Service Accounts configuradas",
  "SERVICE_ACCOUNTS_LOAD_FAILED": "No se han podido cargar las Service Accounts",
  "SERVICE_ACCOUNTS_UPDATE_FAILED": "No se ha podido actualizar la Service Account",
  "SERVICE_ACCOUNTS_CREATE_FAILED": "No se ha podido crear la Service Account",
  "SERVICE_ACCOUNTS_RECONNECT_FAILED": "No se ha podido reconectar la Service Account",
  "SERVICE_ACCOUNTS_DELETE_FAILED": "No se ha podido eliminar la Service Account",
  "SERVICE_ACCOUNTS_DELETE_CONFIRMATION_MODAL_TITLE": "Eliminar Service Account",
  "SERVICE_ACCOUNTS_DELETE_CONFIRMATION_MODAL_MESSAGE": "¿Seguro que deseas eliminar la Service Account <b>'{{ displayName }}'</b>? <br><br> Los formularios con integraciones que utilizan esta Service Account dejarán de funcionar.",
  "GET_STARTED": "Empezar",
  "MANAGE": "Gestionar",
  "SSO_DSYNC": "Directory Synchronisation",
  "SSO": "Inicio de Sesión Único",
  "SSO_DESCRIPTION": "Conecta el directorio de tu empresa y el método de inicio de sesión para simplificar la gestión de usuarios",
  "SSO_SHARED_WARNING": "Esta configuración de Inicio de Sesión Único (SSO) es utilizada por varios clientes. Cualquier cambio afectará a todos.",
  "FEATURE_SINGLE_SIGN_ON_MESSAGE": "<h4>Usa el Inicio de Sesión Único para iniciar sesión con las credenciales que utilizas para todas tus otras aplicaciones.</h4><p></p><br><p>Esta característica está disponible a partir del plan <b>Bosque</b>.</p>",
  "SSO_CONNECTION_ACTIVE": "Activo",
  "SSO_CONNECTION_INACTIVE": "Inactivo",
  "SSO_CONNECTION_DRAFT": "Borrador",
  "SSO_CONNECTION_PENDING": "Pendiente",
  "DSYNC_CONNECTION_LINKED": "Vinculado",
  "DSYNC_CONNECTION_UNLINKED": "Desvinculado",
  "DSYNC_CONNECTION_PENDING": "Pendiente",
  "SSO_STEP_1_TITLE": "Empieza",
  "SSO_STEP_2_TITLE": "Configura el Inicio de Sesión Único (SSO)",
  "SSO_STEP_2_DESCRIPTION": "Conéctate a tu Identity Provider",
  "SSO_STEP_3_TITLE": "Configura Directory Synchronisation",
  "SSO_STEP_3_DESCRIPTION": "Sincroniza tus grupos y usuarios para mantenerlos actualizados",
  "SSO_STEP_4_TITLE": "SSO activado",
  "SSO_STEP_4_DESCRIPTION": "Tus usuarios ya pueden utilizar SSO para iniciar sesión",
  "SSO_IMPORTED_USERS": "{{count}} usuarios importados de tu directorio",
  "SSO_IMPORTED_GROUPS": "{{count}} grupos importados de tu directorio",
  "SSO_NO_IMPORTED_USERS_GROUPS": "No hay usuarios ni grupos importados",
  "SENDING_DOMAIN_TITLE": "Configura tu dominio de email",
  "SENDING_DOMAIN_DESCRIPTION": "Haz que los emails incluyan la marca de tu empresa. Enviaremos emails (como tus informes PDF) desde <b>miempresa.com</b> en lugar de moreapp.com.",
  "SENDING_DOMAIN_SHARED_WARNING": "Varios clientes utilizan este dominio de email. Cualquier cambio afectará a todos.",
  "SENDING_DOMAIN_EDIT_SUBTITLE": "Para cambiar a un dominio diferente, simplemente debes eliminarlo y crear uno nuevo",
  "SENDING_DOMAIN_FIELD_DOMAIN": "Dominio",
  "SENDING_DOMAIN_CREATE_FORM_FIELD_DOMAIN_LABEL": "Dominio",
  "SENDING_DOMAIN_CREATE_FORM_FIELD_DOMAIN_DESCRIPTION": "El dominio desde el que se envían los emails, como <a>miempresa.com</a>",
  "SENDING_DOMAIN_CREATE_FORM_FIELD_DEFAULT_FROM_NAME_LABEL": "Nombre",
  "SENDING_DOMAIN_CREATE_FORM_FIELD_DEFAULT_FROM_NAME_DESCRIPTION": "Un nombre o título que sea reconocible para los destinatarios. En la mayoría de casos, será el nombre de la empresa.",
  "SENDING_DOMAIN_CREATE_FORM_FIELD_DEFAULT_FROM_EMAIL_LABEL": "Dirección Email",
  "SENDING_DOMAIN_CREATE_FORM_FIELD_DEFAULT_FROM_EMAIL_DESCRIPTION": "Se utilizará como dirección de email de origen. Este será también el email de respuesta.",
  "SENDING_DOMAIN_DELETE_TITLE": "¿Eliminar el dominio de email personalizado?",
  "SENDING_DOMAIN_DELETE_MESSAGE": "Esto significa que los emails se enviarán, de nuevo, desde nuestro dominio por defecto. Ya no necesitarás los registros DNS asociados. Puedes eliminarlos de tu proveedor de dominio.",
  "SENDING_DOMAIN_VERIFY_TITLE": "Añadir Registros DNS",
  "SENDING_DOMAIN_VERIFY_DESCRIPTION": "Añade estos registros DNS a tu proveedor de dominios para verificar que eres el propietario de este dominio. La verificación puede tardar hasta 48 horas mientras se propagan los cambios en los registros.",
  "SENDING_DOMAIN_VERIFY_BTN": "Verificar registros",
  "SENDING_DOMAIN_ERROR_INVALID_DOMAIN": "Dominio no válido '{{domain}}'",
  "SENDING_DOMAIN_ERROR_INVALID_FROM_EMAIL": "El email de origen debe tener el dominio '{{domain}}'",
  "FEATURE_SENDING_DOMAIN_MESSAGE": "<h4>Envía emails (como tus informes PDF) desde el dominio de tu empresa</h4><p>Esta característica está disponible a partir del plan <b> Rama </b>.</p>",
  "SETTINGS_SUCCESS_MESSAGE": "Tus ajustes han sido guardados",
  "DYNAMIC_RECIPIENTS_ADD": "Añadir destinatario",
  "DYNAMIC_RECIPIENTS_DEFAULT_HEADER": "Por defecto",
  "DYNAMIC_RECIPIENTS_EMAIL_HEADER": "Campos del Email",
  "DYNAMIC_RECIPIENTS_OTHER_HEADER": "Otros campos",
  "TASKS_NEW": "Nueva tarea",
  "TASKS_PUBLISH_DATE": "Publicada",
  "TASKS_MESSAGE": "Mensaje",
  "TASKS_AND_X_OTHERS": "y {{others}} más",
  "TASKS_FAILED_TO_LOAD_MESSAGE": "Ha habido un fallo al cargar las tareas. Sentimos los inconvenientes ocasionados. Deberías actualizar la página.",
  "TASKS_APPLICATION_WITHOUT_VERSION": "El formulario seleccionado no está publicado. Para crear tareas en este formulario debes publicarlo.",
  "TASK_ADD_TITLE": "Nueva tarea",
  "TASK_ADD_USERS_OR_GROUPS_PLACEHOLDER": "Seleccionar usuario(s) y/o grupo(s)",
  "TASK_ADD_USERS_OR_GROUPS_NUMBER_OF_USERS_IN_GROUP": "{{numberOfUsers}} usuario(s)",
  "TASK_ADD_USERS_OR_GROUPS_NO_USERS_IN_GROUP": "No hay usuarios en este grupo",
  "TASK_ADD_USERS_OR_GROUPS_USERS_IN_THIS_GROUP": "Usuarios del grupo:",
  "TASK_ADD_MESSAGE_LABEL": "Mensaje",
  "TASK_ADD_NO_USERS_OR_GROUPS_MESSAGE": "No hay usuarios o grupos con acceso a este formulario. Por favor, proporciona acceso a tus usuarios, desde el botón 'Administrar usuarios' en la Vista General del formulario.",
  "TASK_ADD_PUBLISH_DATE_LABEL": "Enviar tarea",
  "TASK_DETAIL_TITLE": "Tarea",
  "TASK_DETAIL_MESSAGE": "Mensaje",
  "TASK_DETAIL_ACTIONS": "Acciones",
  "TASK_DETAIL_ACTION_COMPLETE": "Completar",
  "TASK_DETAIL_ACTION_REVOKE": "Anular",
  "TASKS_GRID_NO_ENTRIES": "No hay tareas en este formulario",
  "TASKS_ADD_PUBLISH_IMMEDIATE": "Inmediatamente",
  "TASKS_ADD_PUBLISH_IN_ONE_HOUR": "En 1 hora",
  "TASKS_ADD_PUBLISH_IN_TWO_HOURS": "En 2 horas",
  "TASKS_ADD_PUBLISH_TOMORROW": "Mañana",
  "TASK_DETAIL_FULFILMENT_STATUS_COMPLETED": "Realizada",
  "TASK_DETAIL_FULFILMENT_STATUS_PENDING": "Pendiente",
  "TASK_DETAIL_FULFILMENT_STATUS_DECLINED": "Rechazada",
  "TASK_DETAIL_FULFILMENT_STATUS_TASK_REVOKED": "Anulada",
  "TASK_DETAIL_FULFILMENT_STATUS_TASK_COMPLETED": "Completada",
  "INTEGRATION_GOOGLESHEETS_SUCCESS_MESSAGE": "Registro añadido a la fila: {{range}}",
  "INTEGRATION_TASK_SUCCESS_MESSAGE": "Tareas creadas para:",
  "INTEGRATION_TASK_NO_RECIPIENTS_MESSAGE": "No se ha creado ninguna tarea porque no había destinatarios",
  "INTEGRATION_WORD_SUCCESS_MESSAGE": "El informe se ha enviado por email a:",
  "INTEGRATION_EXCEL_SUCCESS_MESSAGE": "El informe se ha enviado por email a:",
  "APPLICATION_EDITOR_NOTIFICATION_HEADER_AND_FOOTER_LOGO_SELECT_BUTTON": "Sustituir logo predeterminado",
  "COUPON_HINT": "Ahorra un 15% en tu plan con el código",
  "COUPON_CODE": "TRABAJO15",
  "DISCOUNT_BANNER_LABEL": "¡Regístrate ahora y ahorra un 15% en cualquier plan! Añade el código:"
}
;